import React from 'react'

import { ModalBody, Prompt } from '@ingka/modal'
import Button from '@ingka/button'
import RedirectionStyles from './PppRedirection.module.scss'
import PromptStyles from '../components/Prompt.module.scss'

import imageSrc from '../images/no-data-illustration.svg'
import { usePageRedirect } from '../components/Utils/use-page-redirect'
import { useParams } from 'react-router-dom'
import { Route } from '../routes'

export const ExploreRedirectPage = () => {
  const { kpi } = useParams()

  const getPageAndTitle = () => {
    switch (kpi) {
      case 'asissales':
      case 'profitgrowth':
        return [Route.PppSalesExplorePage, 'People + Planet Positive Sales']
      case 'zerowaste':
        return [Route.ZeroWasteExplorePage, 'Zero Waste']
      case 'emissionscontrib':
      case 'emissionslocation':
      case 'footprintlocation':
      case 'planetfootprint':
      case 'planetfootprintmonthly':
      case 'renewable':
      case 'wastedisposal':
      case 'wastelocation':
      case 'wastematerial':
      case 'wastesankey':
      case 'watercontrib':
      case 'waterlocation':
      default:
        return [Route.ClimateExplorePage, 'Operational Climate Footprint']
    }
  }

  const [page, label] = getPageAndTitle()

  const doRedirection = usePageRedirect(page)

  return (
    <div className={RedirectionStyles.PromptContainer}>
      <Prompt title="Explore page has been removed" className={PromptStyles.Prompt} footer={null}>
        <ModalBody>
          <div className={RedirectionStyles.FixedHeightContainer}>
            <img src={imageSrc} className={RedirectionStyles.IllustrationImage} alt="" />
            <p>
              The one unified Explore page has been split to multiple Explore pages, each KPI having its own Explore
              page.
            </p>
            <p>You are being re-directed to the {label} Explore page.</p>
            <p>
              <Button text="OK" type="primary" className={RedirectionStyles.Button} onClick={doRedirection} />
            </p>
          </div>
        </ModalBody>
      </Prompt>
    </div>
  )
}
