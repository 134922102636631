import React from 'react'
import { GoalsResponse } from '../../../../../api/src/types/goals'
import { curry, isNaN } from 'lodash'
import { emptyResult, formatToPercentagesInit } from './helpers'
import { BenchmarkingContext, CurrentFyTimeRange, PreviousFyTimeRange } from '../Provider'
import { getGoalsForLocation } from '../../../../components/Utils/utils'
import { LocationResults } from '../../../../components/Benchmark/SiteResultsTable'
import { BenchmarkTimeRanges, ProfitBenchmarkResult } from '../../../../../api/src/types/benchmark'

const sortByComparisonValue = (desc: boolean) => (a: LocationResults, b: LocationResults) => {
  const nanValue = desc ? Number.MIN_VALUE : Number.MAX_VALUE
  const parseOptional = (a: number | null) => (a ? (isNaN(a) ? nanValue : a) : nanValue)
  const aComparisonValue = parseOptional(a.comparisonValue)
  const bComparisonValue = parseOptional(b.comparisonValue)
  const aPreviousFy = parseOptional(a.previousFy)
  const bPreviousFy = parseOptional(b.previousFy)
  if (aComparisonValue === bComparisonValue || isNaN(aComparisonValue - bComparisonValue)) {
    return desc ? bPreviousFy - aPreviousFy : aPreviousFy - bPreviousFy
  }
  return desc ? bComparisonValue - aComparisonValue : aComparisonValue - bComparisonValue
}

const getPppSales = curry(
  (
    prevTimeRange: PreviousFyTimeRange,
    currTimeRange: CurrentFyTimeRange,
    isChild: boolean,
    goals: GoalsResponse | undefined,
    goalsNextFy: GoalsResponse | undefined,
    result: BenchmarkTimeRanges<ProfitBenchmarkResult>
  ): LocationResults => {
    return {
      selectable: true,
      locationName: result?.ytd?.name,
      ...((result.ytd || result.rolling12) && {
        comparisonValue:
          currTimeRange === 'YTD'
            ? result.ytd.ppp_asis_sales_csum / result.ytd.total_sales_csum
            : result.rolling12?.ppp_asis_sales_csum / result.rolling12?.total_sales_csum
      }),
      ...((result.previousFyYtd || result.previousFy) && {
        previousFy:
          prevTimeRange === 'YTD'
            ? result.previousFyYtd?.ppp_asis_sales_csum / result.previousFyYtd?.total_sales_csum
            : result.previousFy?.ppp_asis_sales_csum / result.previousFy?.total_sales_csum
      }),
      goal: getGoalsForLocation(result.ytd.id, goals)?.pppShareGoal ?? null,
      goalNextFy: getGoalsForLocation(result.ytd.id, goals)?.pppShareGoalNextFY ?? null
    }
  }
)

export const useProfitData = () => {
  const context = React.useContext(BenchmarkingContext)

  if (!context) return null

  const { prevTimeRange, currTimeRange, goals, goalsNextFy, profit } = context.data

  const profitData = !profit
    ? null
    : {
        locationResult:
          profit.status === 'ok'
            ? formatToPercentagesInit(false)(
                getPppSales(prevTimeRange, currTimeRange, false, goals, goalsNextFy, profit.selectedLocation)
              )
            : emptyResult,
        childLocationResults: (profit.status === 'ok' ? Object.values(profit.childLocations).filter(x => x.ytd) : [])
          .map(getPppSales(prevTimeRange, currTimeRange, true, goals, goalsNextFy) as () => LocationResults)
          .map(formatToPercentagesInit(false))
          .sort(sortByComparisonValue(true))
      }

  return {
    profitData
  }
}
