import React from 'react'
import planetaryFootprintTable from '../../images/HowIsThisCalculated/PlanetaryFootprintTable.png'

interface KpiPlanetaryFootprintModalContentProps {
  lastUpdated?: string
}

export const KpiPlanetaryFootprintModalContent: React.FC<KpiPlanetaryFootprintModalContentProps> = ({
  lastUpdated
}) => (
  <>
    <p>
      We convert raw waste, water, and energy usage (in each stream) to CO2e using conversion factors according to their
      respective contributions to climate change, then sum them up to get the total footprint.
    </p>
    <h3>Why is it measured in Carbon Dioxide Equivalent (CO2e)?</h3>
    <p>
      CO2e is a standard unit for measuring carbon footprints and the best way to quantify our impact on climate change.
      It allows us to understand how different substances impact the environment, and which ones are the right ones to
      focus on.
    </p>
    <h3>How the KPI values are calculated?</h3>
    <p>
      Raw data in kWh, litres, ton etc are converted into CO2e by using either supplier information or library values.
    </p>
    <h3 className="RepresentativeExamplesHeader">Here are a few representative examples...</h3>
    <img src={planetaryFootprintTable} className="TableImage" />
    {lastUpdated && <p>{`Last updated on ${lastUpdated} from Sustain`}</p>}
    <h3>Historic refrigerant data FY16-19</h3>
    <p>
      The reporting of refrigerants has been mandatory since FY20. This brings that from earlier years we have scattered
      voluntary reporting. Still the base year for external commitments is FY16 so to handle this we had to estimate
      emissions for the years FY16-19. This means that we have assessed the average emissions and assigned our best
      guessing to each site. Due to this it is only added as CO2 and not by type and kilos leaked.
    </p>
    <h3>Where is the data coming from? How often it is updated? </h3>
    <p>
      Data is taken from Sustain where it is mainly provided by FM locally on a monthly basis. There is one month
      collection period to allow invoices to come meaning September data is collected in October and show in here at the
      end of the first working day in November.
    </p>
    <h3>How to contribute to this KPI?</h3>
    <p>Operational Climate Footprint is driven by:</p>
    <ul>
      <li>
        Energy (electricity, heating & cooling), in which energy efficiency (kWh / m2), renewable energy share (%) are
        some of the metrics followed.
      </li>
      <li>Refrigerant leakages (amounts and types) </li>
      <li>Waste (amounts and treatment), in which total waste (tonnes) and recycling rate (%) are followed. </li>
      <li>Water (amounts and source (m3)) is measured. </li>
    </ul>
    <p>
      From FY25 the footprint scope will change to match the internal and external commitments, linked to Ingkas
      commitments to Science Based Targets initiative (SBTi). The scope is then extended with{' '}
    </p>
    <ul>
      <li>own vehicle fuel to ensure fill scope 1+2 is covered and</li>
      <li>
        upstream energy emissions to include the indirect emissions caused by producing and distributing the energy to
        us{' '}
      </li>
    </ul>
    <p>
      The water footprint is taken out of SBTi scope as a new KPI is coming in FY25 to focus more on water consumption.{' '}
    </p>
    <p>As Ingka overall we steer performance by; </p>
    <ul>
      <li>
        Strategic decisions ensuring we buy renewable electricity and invest in future technology for renewable heating
        & cooling, low impact refrigerants (in heat pumps and freezers) and contracting partners supporting our vision
        (mobility, waste handling etc).
      </li>
      <li>
        Encouraging daily awareness in the “co-worker view” of 4P. There you see the impact our behaviors have by store
        for some main 4P KPIs.
      </li>
    </ul>
    <p>
      We can all contribute to decision making ensuring we save resources, prevent waste etc. The biggest contributions
      to the climate footprint are usually using fossil fuels like non-renewable electricity or natural gas along with
      sending waste to landfill. Once energy is renewable and waste recycled the “joker” is usually refrigerant leakages
      causing a spiking footprint in the month the systems are refilled.
    </p>
    <p>
      Use the{' '}
      <a className="Underlined" href="https://4p.ingka.com/ALL/whatif-planet?func%5B0%5D=ALL&functionArea=ingka">
        “What if” scenario tool
      </a>{' '}
      in 4P to find out what contributor impacts most for Ingka, country, function or site.{' '}
    </p>
  </>
)
