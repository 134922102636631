import React from 'react'
import { GridHr, Title } from '../../../components/Benchmark/GridHelpers'
import { TimeRangeSelector } from '../../../components/Benchmark/TimeRangeSelector'
import { BenchmarkingContext } from './Provider'
import { LoadingSkeleton } from '../../../components/LoadingSkeleton'
import { BenchmarkTableRow, TableRowKey } from '../../../components/Benchmark/BenchmarkTableRow'
import { useProfitData } from './hooks/useProfitData'
import { useBenchmarkHelperData } from './hooks/helpers'

export const HealthyLivingTable = () => {
  const context = React.useContext(BenchmarkingContext)
  const data = useProfitData()
  const { prevOptions, currOptions, currFyLabel, nextFyLabel } = useBenchmarkHelperData()

  if (!context) return null

  const { prevTimeRange, currTimeRange, expandedKey } = context.data

  const setPrevTimeRange = (value: 'YTD' | 'Result') => {
    context.dispatch({ type: 'prevTimeRange', payload: value })
  }

  const setCurrTimeRange = (value: 'YTD' | 'Rolling 12 mos') => {
    context.dispatch({ type: 'currTimeRange', payload: value })
  }

  const commonRowProps = {
    expandedKey,
    toggleExpanded: context.toggleExpanded
  }

  return (
    <>
      <div className="TableHeadTitle Health">Healthy & Sustainable Living</div>
      <div className="Table">
        <Title className="KPI">KPI / PI</Title>
        <Title className="SiteCountry">Site/country</Title>
        <TimeRangeSelector
          className="ResultPrevFy Title"
          options={prevOptions}
          currentValue={prevTimeRange === 'YTD' ? prevOptions[0] : prevOptions[1]}
          onValueChanged={value => (value === prevOptions[0] ? setPrevTimeRange('YTD') : setPrevTimeRange('Result'))}
        />
        <TimeRangeSelector
          className="Comparison Title"
          options={currOptions}
          currentValue={currTimeRange === 'YTD' ? currOptions[0] : currOptions[1]}
          onValueChanged={value =>
            value === currOptions[0] ? setCurrTimeRange('YTD') : setCurrTimeRange('Rolling 12 mos')
          }
        />
        <Title className="Goal">{currFyLabel} Goal</Title>
        <Title className="GoalNext">{nextFyLabel} Goal</Title>
        <GridHr isHead />
        {!data?.profitData ? (
          <LoadingSkeleton />
        ) : (
          <BenchmarkTableRow
            kpiUnits="share %"
            locationResults={data.profitData.locationResult}
            childLocationResults={data.profitData.childLocationResults}
            rowKey={TableRowKey.PppSales}
            {...commonRowProps}
          />
        )}
      </div>
    </>
  )
}
