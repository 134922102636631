import { format, isSameMonth } from 'date-fns'
import React, { Fragment } from 'react'
import classNames from 'classnames'

import { CountryCode, EnergyEfficiencyBenchmark, EnergyEfficiencyRolling } from '../../../../api/src/common-types'
import { ChartContainer, isDataPoint, Serie } from '../../../components/BaseGraphs/ChartContainer'
import { lineChart } from '../../../components/BaseGraphs/GraphUtil'
import { TopBar } from '../../../components/TopBar'
import { formatAbsoluteNumber } from '../../../components/Utils/format'
import { getEnergyEfficiencyBenchmarking, getEnergyEfficiencyRolling, getGoals } from '../../../lib/APIClient'
import { useSharedSelections } from '../../../SharedSelections'

import '../KPIPage.scss'
import colours from '../../../Colours.module.scss'
import {
  getCountry,
  getLocationId,
  getLocationLabel,
  isSiteId,
  mapGoalsResponseWithNext
} from '../../../components/Utils/utils'
import { KpiModalState, KpiPageLearnMoreModal } from '../../../components/Modal'
import { PageHeader } from '../../../components/PageHeader'
import {
  Benchmarking,
  BenchmarkingModal,
  CardRow,
  DataSourceAndModalButton,
  HeadingItem,
  KPIPerformance,
  MainCard,
  RenderBenchmarkType,
  Stripe
} from '../../../components/KPIPage'
import { NoDataView } from '../../../components/BaseGraphs/NoDataView'
import { useDataAvailabilityContext, useLocations } from '../../../context'
import { useDocumentTitle } from '../../../components/Utils/use-document-title'
import { GoalStatus } from '../../../components/SnapshotTopCards'
import { Route } from '../../../routes'

export const EnergyEfficiencyKPIPage = () => {
  useDocumentTitle('Energy Efficiency')

  const [{ func }] = useSharedSelections()
  const { dataAvailability } = useDataAvailabilityContext()
  const { currentLocation, locations } = useLocations()
  const [footprint, setFootprint] = React.useState<EnergyEfficiencyRolling[]>()
  const [dates, setDates] = React.useState<Date[]>()
  const [lastUpdated, setLastUpdated] = React.useState('')
  const [modalState, setModalState] = React.useState<KpiModalState>({ isOpen: false })
  const [benchmarks, setBenchmarks] = React.useState<EnergyEfficiencyBenchmark[]>()
  const [benchmarkModalOpen, setBenchmarkModalOpen] = React.useState(false)
  const locationId = getLocationId(currentLocation)
  const currentFY = dataAvailability?.energyCurrentFY ?? 2024
  const currentFYShort = currentFY - 2000

  const page = Route.EnergyEfficiencyKPIPage

  React.useEffect(() => {
    if (!locations || locations.length === 0) return

    setFootprint(undefined)
    setBenchmarks(undefined)
    setLastUpdated('')

    const countryCode = isSiteId(locationId)
      ? getCountry(locationId, locations).countryCode
      : (locationId as CountryCode)

    getGoals(countryCode, func, currentFY)
      .then(result => mapGoalsResponseWithNext(result, 'energyEfficiencyGoal', 'energyEfficiencyGoalNextFY'))
      .then(goals => {
        getEnergyEfficiencyBenchmarking(countryCode, func, currentFY).then(benchmarks =>
          setBenchmarks(benchmarks.map(b => ({ ...b, goal: goals[b.id]?.goal, goalNextFY: goals[b.id]?.goalNextFY })))
        )
      })
    getEnergyEfficiencyRolling(locationId, func, currentFY).then(result => {
      setFootprint(result.data)
      setDates(result.dates.map(d => new Date(d)))
      result.lastUpdated && setLastUpdated(format(new Date(result.lastUpdated), 'dd/MM/yyyy'))
    })
  }, [JSON.stringify(locations), JSON.stringify(func), JSON.stringify(currentLocation)])

  const countryBenchmark: EnergyEfficiencyBenchmark = React.useMemo(() => {
    return (
      benchmarks?.find(benchmark => benchmark.id === getLocationId(currentLocation)) ?? {
        id: getLocationId(currentLocation),
        label: getLocationLabel(currentLocation),
        area: 0,
        goal: 0,
        goalNextFY: 0,
        selectable: true,
        previousFy: 0,
        previousYtd: 0,
        currentYtd: 0,
        buildingkwh: 0,
        rolling: 0
      }
    )
  }, [JSON.stringify(currentLocation), JSON.stringify(benchmarks)])

  const [selectedLocationBenchmark, comparisonBenchmarks] = React.useMemo(() => {
    const locationId = getLocationId(currentLocation)
    if (benchmarks === undefined) {
      return [undefined, []]
    }
    const selectedLocationBenchmark = isSiteId(locationId)
      ? benchmarks.find(b => b.id === locationId)
      : countryBenchmark

    return [
      selectedLocationBenchmark,
      benchmarks
        .filter(b => b.id !== locationId)
        .sort((a, b) => b.goal - a.goal)
        .slice(0, selectedLocationBenchmark ? 3 : 4)
    ]
  }, [JSON.stringify(benchmarks)])

  const benchmarksWithoutCurrentLocation = React.useMemo(() => {
    const isSite = isSiteId(locationId)
    return benchmarks?.filter(b => b.id !== locationId && (isSite ? b.id.length >= 3 : true)) ?? []
  }, [JSON.stringify(benchmarks)])

  const renderBenchmark: RenderBenchmarkType<EnergyEfficiencyBenchmark> = (benchmark, keys, classes) => {
    const prevFYValue = Number(benchmark[keys[0]])
    const currFYValue = Number(benchmark[keys[1]])
    const goal = Number(benchmark[keys[2]])
    const goalNextFY = Number(benchmark[keys[3]])

    const trackClass =
      keys[1] !== 'rolling' ? 'Black' : !benchmark.goal || +benchmark[keys[1]] < benchmark.goal ? 'OnTrack' : 'YTD'

    return (
      <Fragment key={benchmark.id}>
        <div className={classNames('FirstItem', classes)}>{benchmark.label}</div>
        <div className={classNames(classes)}>{isNaN(prevFYValue) ? 'N/A' : prevFYValue.toFixed(1)}</div>
        <div className={classNames(classes, trackClass)}>{isNaN(currFYValue) ? 'N/A' : currFYValue.toFixed(1)}</div>
        <div className={classNames(classes)}>{isNaN(goal) || !goal ? 'N/A' : goal.toFixed(1)}</div>
        <div className={classNames(classes)}>{isNaN(goalNextFY) || !goalNextFY ? 'N/A' : goalNextFY.toFixed(1)}</div>

        <div />
      </Fragment>
    )
  }

  const benchmarkHeaders: HeadingItem<EnergyEfficiencyBenchmark>[] = [
    [
      { name: `FY${currentFYShort - 1} Result`, key: 'previousFy' },
      { name: `FY${currentFYShort - 1} YTD`, key: 'previousYtd' }
    ],
    [
      { name: 'Rolling 12 mos', key: 'rolling' },
      { name: `FY${currentFYShort} YTD`, key: 'currentYtd' }
    ],
    [{ name: `FY${currentFYShort} Goal`, key: 'goal' }],
    [{ name: `FY${currentFYShort + 1} Goal`, key: 'goalNextFY' }]
  ]

  const ytdSummary = selectedLocationBenchmark?.rolling ? (
    <span>
      {selectedLocationBenchmark.rolling.toFixed(1)} <span className="Label">kWh/m²</span>
    </span>
  ) : undefined

  return (
    <div className="KPIPage">
      <TopBar currentPage={Route.EnergyEfficiencyKPIPage} useInFlexLayout />
      <PageHeader className="ClimateFootprintHeader" route={Route.EnergyEfficiencyKPIPage} />
      <div className="PageContent">
        <Stripe title="Climate Footprint - Energy Efficiency">
          <DataSourceAndModalButton
            dataSource="Sustain"
            lastUpdated={lastUpdated}
            onClick={() => setModalState({ isOpen: true, page })}
          />
        </Stripe>
        {benchmarks?.length === 0 || footprint?.length === 0 ? (
          <NoDataView />
        ) : (
          <>
            <CardRow className="BenchmarkingAndGoals">
              <Benchmarking
                key="benchmarking"
                benchmarks={
                  comparisonBenchmarks
                    ? [...(selectedLocationBenchmark ? [selectedLocationBenchmark] : []), ...comparisonBenchmarks]
                    : []
                }
                label="building kWh/m²"
                headers={benchmarkHeaders}
                locationId={locationId}
                openModal={() => setBenchmarkModalOpen(true)}
                renderBenchmark={renderBenchmark}
                totalLocations={benchmarksWithoutCurrentLocation.length ?? 0}
              />
              <KPIPerformance
                key="goals"
                heading="KPI Performance"
                units={['Rolling 12 mos']}
                kpis={
                  footprint?.length === 0
                    ? []
                    : [
                        {
                          key: 'Building kWh',
                          unit: 'building kWh',
                          value: selectedLocationBenchmark?.buildingkwh
                            ? formatAbsoluteNumber(selectedLocationBenchmark?.buildingkwh)
                            : ''
                        },
                        {
                          key: 'Total m²',
                          unit: 'm²',
                          value: selectedLocationBenchmark?.area
                            ? formatAbsoluteNumber(selectedLocationBenchmark?.area)
                            : ''
                        },
                        {
                          key: 'Energy Efficiency',
                          unit: 'building kWh/m²',
                          value: selectedLocationBenchmark?.rolling?.toFixed(1) ?? '',
                          keyClassNames: ['Bold'],
                          valueClassNames: ['Bold'],
                          colorClass:
                            !selectedLocationBenchmark?.goal ||
                            (selectedLocationBenchmark?.goal ?? 0) >= (selectedLocationBenchmark?.rolling ?? 0)
                              ? GoalStatus.OnTrack
                              : GoalStatus.NotOnTrack
                        },
                        {
                          key: `Full FY${(dataAvailability?.energyCurrentFY || 2025) - 2000} Goal`,
                          unit: 'building kWh/m²',
                          value: selectedLocationBenchmark?.goal?.toFixed(1) ?? '',
                          keyClassNames: ['Bold'],
                          valueClassNames: ['Bold']
                        }
                      ]
                }
              />
            </CardRow>
            <MainCard title="Energy Efficiency" titleExtantion="Rolling 12 mos" subtitle={ytdSummary}>
              <div className="GraphContainer">
                <ChartContainer
                  domain={dates}
                  series={formatEnergyEfficiencySeries(footprint, dates, selectedLocationBenchmark?.goal)}
                  generator={lineChart}
                  dateFormat="month"
                  lineChartConfiguration={{ focusStyle: 'none', startFromZero: false }}
                  yAxisTitle="kWh/m²"
                  showDecimals
                />
              </div>
            </MainCard>
          </>
        )}
      </div>
      <KpiPageLearnMoreModal
        lastUpdated={lastUpdated}
        modalState={modalState}
        onClose={() => setModalState({ isOpen: false })}
      />
      {benchmarks && benchmarks.length > 0 && (
        <BenchmarkingModal
          benchmarks={benchmarksWithoutCurrentLocation}
          closeFn={() => setBenchmarkModalOpen(false)}
          footerBenchmark={countryBenchmark}
          headers={benchmarkHeaders}
          isOpen={benchmarkModalOpen}
          locationId={getLocationId(currentLocation)}
          renderBenchmark={renderBenchmark}
          sortBy="currentYtd"
          sortDirection="asc"
          title="building kWh/m²"
        />
      )}
    </div>
  )
}

export const formatEnergyEfficiencySeries = (
  data: EnergyEfficiencyRolling[] | undefined,
  domain: Date[] | undefined,
  goal?: number
): Serie[] | undefined => {
  if (data === undefined || domain === undefined) {
    return undefined
  }
  const noData = data.every(d => !d.currentFY && !d.previousFY)
  if (noData) {
    return []
  }
  const fy = data[0].fiscalYear

  const dataWithDates = domain.map(date => {
    const d = data.find(d => isSameMonth(new Date(d.readableDate), date))
    return {
      ...d,
      date,
      curr: d?.currentFY,
      prev: d?.previousFY
    }
  })

  return [
    {
      name: `FY${fy - 1}`,
      color: colours.offWhite1,
      fill: colours.grey1,
      data: dataWithDates.map(d => ({ x: d.date, y: d.prev })).filter(isDataPoint)
    },
    {
      name: `FY${fy}`,
      color: colours.darkBlue1,
      data: dataWithDates.map(d => ({ x: d.date, y: d.curr })).filter(isDataPoint)
    },
    !!goal && {
      name: `FY${fy} Goal`,
      color: colours.lightBlue2,
      data: data.map(d => ({ x: new Date(d.readableDate), y: goal }))
    }
  ].filter((s): s is Serie => s !== false)
}
