import { format, isSameMonth } from 'date-fns'
import React, { Fragment } from 'react'
import classNames from 'classnames'
import { toPairs } from 'lodash'

import {
  ClimateBenchmark,
  CountryCode,
  Insight,
  SiteFunction,
  YtdFootprintAndGoals
} from '../../../../api/src/common-types'
import { ChartContainer, isDataPoint, Serie } from '../../../components/BaseGraphs/ChartContainer'
import { lineChart } from '../../../components/BaseGraphs/GraphUtil'
import { TopBar } from '../../../components/TopBar'
import { formatAbsoluteNumber, formatValueAsTons } from '../../../components/Utils/format'
import {
  getClimateBenchmarking,
  getClimateSBTi,
  getInsights,
  getMonthlyFootprint,
  useEffectWithAbort
} from '../../../lib/APIClient'
import { useSharedSelections } from '../../../SharedSelections'

import '../KPIPage.scss'
import colours from '../../../Colours.module.scss'
import { PlanetFootprintGraphSideBarCards } from '../../../components/GraphSideBarCards'
import {
  getCluster,
  getCountry,
  getLocationId,
  getLocationLabel,
  getLocationSelector,
  isCluster,
  isSiteId
} from '../../../components/Utils/utils'
import { BenchmarkingDownloadModal, KpiModalState, KpiPageLearnMoreModal } from '../../../components/Modal'
import { Link } from '../../../components/Link'
import { PageHeader } from '../../../components/PageHeader'
// import { InPageInsights } from '../../../components/InPageInsights'
import {
  Benchmarking,
  BenchmarkingModal,
  CardRow,
  DataSourceAndModalButton,
  KPI,
  KPIPerformance,
  MainCard,
  RenderBenchmarkType,
  Stripe
} from '../../../components/KPIPage'
import Button from '@ingka/button'
import { NoDataView } from '../../../components/BaseGraphs/NoDataView'
import { useLocations } from '../../../context'
import { useDocumentTitle } from '../../../components/Utils/use-document-title'
import { GoalStatus } from '../../../components/SnapshotTopCards'
import { Route } from '../../../routes'
import InlineMessage from '@ingka/inline-message'
import { SwitchScopeBtn } from '../../../components/SwitchScopeBtn'

const LAST_MONTH_INDEX = 11

export const ClimateKPIPage = () => {
  useDocumentTitle('Operational Climate Footprint')

  const [{ func, scope }] = useSharedSelections()
  const { currentLocation, locations, clusters } = useLocations()
  const [footprint, setFootprint] = React.useState<YtdFootprintAndGoals[]>()
  const [dates, setDates] = React.useState<Date[]>()
  const [lastUpdated, setLastUpdated] = React.useState('')
  const [, setInsights] = React.useState<Insight[] | null>(null)
  const [modalState, setModalState] = React.useState<KpiModalState>({ isOpen: false })
  const [benchmarks, setBenchmarks] = React.useState<ClimateBenchmark[]>()
  const [benchmarkModalOpen, setBenchmarkModalOpen] = React.useState(false)
  const [modalDownloadState, setModalDownloadState] = React.useState<boolean>(false)
  const locationId = getLocationId(currentLocation)

  const isSbti = scope.includes('sbti')
  const [isOld, setIsOld] = React.useState<boolean>(!isSbti)

  useEffectWithAbort(
    signal => {
      setFootprint(undefined)
      setInsights(null)
      setLastUpdated('')

      getMonthlyFootprint(
        {
          ...getLocationSelector(locationId, getCluster(clusters, locationId)?.countryCodes),
          func,
          isOld
        },
        signal
      ).then(result => {
        setFootprint(result.data)
        setDates(result.dates.map(d => new Date(d)))
        result.lastUpdated && setLastUpdated(format(new Date(result.lastUpdated), 'dd/MM/yyyy'))
      })

      if (!currentLocation.isCluster) {
        getInsights({
          locationId,
          func
        }).then(result => setInsights(result.filter(({ type }) => type === 'climate-footprint')))
      }
    },
    [JSON.stringify(currentLocation), JSON.stringify(func), isOld]
  )

  useEffectWithAbort(
    signal => {
      if (!locations || locations.length === 0) {
        return
      }
      setBenchmarks(undefined)
      const locationId = getLocationId(currentLocation)
      if (isCluster(locationId)) {
        return
      }
      const countryCode = isSiteId(locationId)
        ? getCountry(locationId, locations).countryCode
        : (locationId as CountryCode)
      !isOld
        ? getClimateSBTi(countryCode, func, signal).then(setBenchmarks)
        : getClimateBenchmarking(countryCode, func, signal).then(setBenchmarks)
    },
    [JSON.stringify(locations), JSON.stringify(func), JSON.stringify(currentLocation), isOld]
  )

  const [kpiPerformance, ytdPerformance, currentFY] = React.useMemo(() => {
    if (footprint === undefined) {
      return [undefined, undefined, undefined]
    }

    const locationId = getLocationId(currentLocation)
    const benchmark = benchmarks?.find(b => b.id === locationId)
    const ytdGoal = benchmark?.goalYtd ?? NaN
    const latest = footprint[footprint.length - 1]
    const ytd = footprint.reduce((acc, d) => acc + (d.currentFY ?? 0), 0)
    const ytdEnergy = footprint.reduce((acc, d) => acc + (d.energyCurrentFY ?? 0), 0)
    const ytdWaste = footprint.reduce((acc, d) => acc + (d.wasteCurrentFY ?? 0), 0)
    const ytdWater = footprint.reduce((acc, d) => acc + (d.waterCurrentFY ?? 0), 0)
    const ytdRefrigerants = footprint.reduce((acc, d) => acc + (d.refrigerantsCurrentFY ?? 0), 0)

    return isOld
      ? [
          {
            Energy: formatValueAsTons(ytdEnergy),
            Refrigerants: formatValueAsTons(ytdRefrigerants),
            Waste: formatValueAsTons(ytdWaste),
            Water: formatValueAsTons(ytdWater),
            'Total Climate Footprint': formatValueAsTons(ytd),
            [`FY${latest.fiscalYear} Goal`]: formatGoal(ytdGoal, func)
          },
          {
            value: formatValueAsTons(ytd),
            goalStatus: ytd < ytdGoal * 1000 ? GoalStatus.OnTrack : GoalStatus.NotOnTrack,
            ytdGoal,
            energy: formatValueAsTons(ytdEnergy),
            waste: formatValueAsTons(ytdWaste),
            water: formatValueAsTons(ytdWater)
          },
          latest.fiscalYear
        ]
      : [
          {
            Energy: formatAbsoluteNumber(ytdEnergy),
            Refrigerants: formatAbsoluteNumber(ytdRefrigerants),
            Waste: formatAbsoluteNumber(ytdWaste),
            'Total Climate Footprint': formatAbsoluteNumber(ytd / 1000),
            [`FY${latest.fiscalYear} Goal`]: formatGoal(ytdGoal, func)
          },
          {
            value: formatAbsoluteNumber(ytd / 1000),
            goalStatus: ytd < ytdGoal * 1000 ? GoalStatus.OnTrack : GoalStatus.NotOnTrack,
            ytdGoal,
            energy: formatAbsoluteNumber(ytdEnergy),
            waste: formatAbsoluteNumber(ytdWaste),
            Refrigerants: formatAbsoluteNumber(ytdRefrigerants)
          },
          latest.fiscalYear
        ]
  }, [JSON.stringify(footprint), JSON.stringify(benchmarks)])

  const benchmarkWithCurrGoal =
    footprint &&
    benchmarks?.map((bench, ind) =>
      ind === 0
        ? {
            ...bench,
            goal: Number.isFinite(footprint[LAST_MONTH_INDEX].goal)
              ? (footprint[LAST_MONTH_INDEX].goal as number) / 1000
              : NaN,
            goalYtd: Number.isFinite(bench.goalYtd) ? bench.goalYtd : NaN,
            goalNextFy: Number.isFinite(footprint[LAST_MONTH_INDEX].goalNextFy)
              ? (footprint[LAST_MONTH_INDEX].goalNextFy as number) / 1000
              : NaN
          }
        : {
            ...bench,
            goal: Number.isFinite(bench.goal) ? bench.goal : NaN,
            goalYtd: Number.isFinite(bench.goalYtd) ? bench.goalYtd : NaN,
            goalNextFy: Number.isFinite(bench.goalNextFy) ? bench.goalNextFy : NaN
          }
    )

  const countryBenchmark: ClimateBenchmark = React.useMemo(() => {
    return (
      benchmarkWithCurrGoal?.find(benchmark => benchmark.id === getLocationId(currentLocation)) ?? {
        id: getLocationId(currentLocation),
        label: getLocationLabel(currentLocation),
        selectable: true,
        previousFy: 0,
        previousYtd: 0,
        currentYtd: 0,
        rolling: 0,
        goal: 0,
        goalYtd: 0,
        goalNextFy: 0
      }
    )
  }, [JSON.stringify(currentLocation), JSON.stringify(benchmarkWithCurrGoal)])

  const [selectedLocationBenchmark, comparisonBenchmarks] = React.useMemo(() => {
    const locationId = getLocationId(currentLocation)
    if (benchmarkWithCurrGoal === undefined) {
      return [undefined, []]
    }
    const selectedLocationBenchmark = isSiteId(locationId)
      ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        benchmarkWithCurrGoal.find(b => b.id === locationId)!
      : countryBenchmark

    return [
      selectedLocationBenchmark,
      benchmarkWithCurrGoal
        .filter(b => b.id !== locationId && b.selectable)
        .map(b => ({
          ...b,
          distance: Math.abs(Number(b.goal) - Number(selectedLocationBenchmark?.goal))
        }))
        .sort((a, b) => (b.goal || 0) - (a.goal || 0))
        .slice(0, selectedLocationBenchmark ? 3 : 4)
    ]
  }, [JSON.stringify(benchmarkWithCurrGoal)])

  const benchmarksWithoutCurrentLocation = React.useMemo(() => {
    const isSite = isSiteId(locationId)
    return benchmarkWithCurrGoal?.filter(b => b.id !== locationId && (isSite ? b.id.length >= 3 : true)) ?? []
  }, [JSON.stringify(benchmarkWithCurrGoal)])

  const renderBenchmark: RenderBenchmarkType<ClimateBenchmark> = (benchmark, keys, classes) => (
    <Fragment key={benchmark.id}>
      <div className={classNames('FirstItem', classes)}>
        {benchmark.label}
        {!benchmark.selectable && isSiteId(benchmark.id) ? ' (closed)' : ''}
      </div>
      <div className={classNames(classes)}>{formatValueAsTons(benchmark[keys[0]] as number)}</div>
      <div
        className={classNames(classes, {
          YTD: benchmark.goalYtd && keys[1] === 'currentYtd',
          OnTrack: Number(benchmark[keys[1]]) < Number(benchmark.goalYtd) * 1000
        })}
      >
        {formatValueAsTons(benchmark[keys[1]] as number)}
      </div>
      <div className={classNames(classes)}>{formatGoal(Number(benchmark[keys[2]]), func)}</div>
      <div className={classNames(classes)}>{formatGoal(Number(benchmark[keys[3]]), func)}</div>
      <div />
    </Fragment>
  )

  const ytdSummary = ytdPerformance ? (
    <span>
      {ytdPerformance.value} <span className="Label">tonnes CO2e YTD</span>
    </span>
  ) : undefined

  const exploreButton = (
    <Link page={Route.ClimateExplorePage}>
      <Button text="Open Explore" type="primary" small />
    </Link>
  )

  const dataForDownload = [...benchmarksWithoutCurrentLocation, countryBenchmark].map(d => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { selectable, ...rest } = d
    return rest
  })

  return (
    <div className="KPIPage">
      <TopBar currentPage={Route.ClimateKPIPage} useInFlexLayout exploreButton={exploreButton} />
      <PageHeader className="ClimateFootprintHeader  ClimateFootprintHeader-whithSBTi" route={Route.ClimateKPIPage}>
        <SwitchScopeBtn
          setIsOld={setIsOld}
          isOld={isOld}
          textLeftBtn={`FY24 - Old Scope`}
          textRightBtn={`FY25 - New Scope`}
          queryParamFirst="oldscope"
          queryParamLast="sbti"
        />
      </PageHeader>
      <div className="PageContent">
        <Stripe
          title="Operational Climate Footprint"
          setModalDownloadState={setModalDownloadState}
          isLoading={benchmarks ? false : true}
        >
          <DataSourceAndModalButton
            dataSource="Sustain"
            lastUpdated={lastUpdated}
            onClick={() => setModalState({ isOpen: true, page: Route.ClimateKPIPage })}
          />
        </Stripe>
        <div className="InlineMessageWrapper">
          <InlineMessage
            body={
              isOld
                ? 'Please note the FY25-30 goals are set with new scope and available at "FY25 - new scope", see top right corner. Goals and actions show on the level formally set and now showing according to the submissions available 3rd July.'
                : 'Performance now include fuel from own vehicles based on estimates number of vehicles and spend on fuel. Goals and actions show on the level formally set, from FY25 the formal goals are set with new scope to align internal and external goals, now showing according to the submissions available 3rd July.'
            }
            variant="cautionary"
          />
          <InlineMessage
            body="The full FY24 performance is preliminary and will be updated on 15th October when the last historic changes and DQA are done."
            variant="cautionary"
          />
        </div>
        {benchmarks?.length === 0 || footprint?.length === 0 ? (
          <NoDataView />
        ) : (
          <>
            <CardRow className="BenchmarkingAndGoals">
              <Benchmarking
                key="benchmarking"
                benchmarks={
                  comparisonBenchmarks
                    ? [...(selectedLocationBenchmark ? [selectedLocationBenchmark] : []), ...comparisonBenchmarks]
                    : []
                }
                label="tonnes CO2e"
                headers={[
                  currentFY
                    ? [
                        { name: `FY${currentFY - 1} YTD`, key: 'previousYtd' },
                        { name: `FY${currentFY - 1}`, key: 'previousFy' }
                      ]
                    : [],
                  currentFY
                    ? [
                        { name: `FY${currentFY} YTD`, key: 'currentYtd' },
                        { name: 'Rolling 12 mos', key: 'rolling' }
                      ]
                    : [],
                  currentFY ? [{ name: `FY${currentFY} Goal`, key: 'goal' }] : [],
                  currentFY ? [{ name: `FY${currentFY + 1} Goal`, key: 'goalNextFy' }] : []
                ]}
                locationId={locationId}
                openModal={() => setBenchmarkModalOpen(true)}
                renderBenchmark={renderBenchmark}
                totalLocations={benchmarksWithoutCurrentLocation.length ?? 0}
                tooltip={`The ${
                  locationId === 'ALL' ? 'countries' : 'stores'
                } listed below are those whose goals are closest to the selected ${
                  locationId.length === 32 ? 'store' : 'country'
                }.`}
              />
              <KPIPerformance
                key="goals"
                heading="KPI Performance"
                units={isOld ? ['tonnes CO2e YTD'] : ['tonnes CO2e']}
                classes={!isOld ? 'KPIPerformance-sbti' : ''}
                kpis={
                  kpiPerformance && ytdPerformance
                    ? [
                        ...toPairs(kpiPerformance).map(([key, value], i): KPI => {
                          const isPerformance = i === (isOld ? 4 : 3)
                          const isGoal = i === (isOld ? 5 : 4)
                          const boldClassName = ['Bold']
                          const onTrackClassNames = [
                            'Bold',
                            Number.isNaN(ytdPerformance.ytdGoal) ? '' : 'YTD',
                            ytdPerformance.goalStatus === GoalStatus.OnTrack ? 'OnTrack' : ''
                          ]
                          return {
                            key,
                            value,
                            keyClassNames: isGoal || isPerformance ? boldClassName : [],
                            valueClassNames: isGoal ? boldClassName : isPerformance ? onTrackClassNames : []
                          }
                        })
                      ]
                    : []
                }
              />
            </CardRow>
            <MainCard title="Total Climate Footprint" subtitle={ytdSummary}>
              <div className="GraphContainer">
                <ChartContainer
                  domain={dates}
                  series={formatFootprintSeries(footprint, dates, true)}
                  generator={lineChart}
                  dateFormat="month"
                  lineChartConfiguration={{ focusStyle: 'none', startFromZero: true }}
                  yAxisTitle="tonnes CO2e"
                />
              </div>
            </MainCard>
            <PlanetFootprintGraphSideBarCards
              className={`GraphRow ${isOld ? '' : 'three-in-row'}`}
              graphData={footprint}
              dates={dates}
              isOld={isOld}
            />
            {/* {insights && insights.length > 0 && !isOld && <InPageInsights insights={insights} />} */}
            <Stripe
              title="Want to learn more about the Operational Climate Footprint?"
              subtitle="Explore contains additional charts and time selections."
            >
              {exploreButton}
            </Stripe>
          </>
        )}
      </div>
      <KpiPageLearnMoreModal
        lastUpdated={lastUpdated}
        modalState={modalState}
        onClose={() => setModalState({ isOpen: false })}
      />
      {benchmarks && benchmarks.length > 0 && (
        <BenchmarkingModal
          benchmarks={benchmarksWithoutCurrentLocation}
          closeFn={() => setBenchmarkModalOpen(false)}
          footerBenchmark={countryBenchmark}
          headers={[
            currentFY
              ? [
                  { name: `FY${currentFY - 1} YTD`, key: 'previousYtd' },
                  { name: `FY${currentFY - 1}`, key: 'previousFy' }
                ]
              : [],
            currentFY
              ? [
                  { name: `FY${currentFY} YTD`, key: 'currentYtd' },
                  { name: 'Rolling 12 mos', key: 'rolling' }
                ]
              : [],
            currentFY ? [{ name: `FY${currentFY} Goal`, key: 'goal' }] : [],
            currentFY ? [{ name: `FY${currentFY + 1} Goal`, key: 'goalNextFy' }] : []
          ]}
          isOpen={benchmarkModalOpen}
          locationId={getLocationId(currentLocation)}
          renderBenchmark={renderBenchmark}
          sortBy="currentYtd"
          sortDirection="asc"
          title="tonnes CO2e"
        />
      )}
      <div className="KPIPage__modal-header">
        <BenchmarkingDownloadModal
          lastUpdated={lastUpdated}
          modalState={modalDownloadState}
          onClose={() => setModalDownloadState(false)}
          data={dataForDownload}
        />
      </div>
    </div>
  )
}

export const formatFootprintSeries = (
  data: YtdFootprintAndGoals[] | undefined,
  domain: Date[] | undefined,
  showGoals: boolean
): Serie[] | undefined => {
  if (data === undefined || domain === undefined) {
    return undefined
  }
  if (data.length === 0) {
    return []
  }
  const fy = data[0].fiscalYear
  let currSum = 0
  let prevSum = 0
  const withSums = domain.map(date => {
    const d = data.find(d => isSameMonth(new Date(d.readableDate), date))
    currSum += d?.currentFY ?? 0
    prevSum += d?.previousFY ?? 0
    return {
      ...d,
      date,
      currSum: d?.currentFY ? currSum : undefined,
      prevSum: d?.previousFY ? prevSum : undefined
    }
  })

  return [
    {
      name: `FY${fy - 1}`,
      color: colours.offWhite1,
      fill: colours.grey1,
      data: withSums.map(d => ({ x: d.date, y: d.prevSum ? d.prevSum / 1000 : undefined })).filter(isDataPoint)
    },
    {
      name: `FY${fy}`,
      color: colours.darkBlue1,
      data: withSums.map(d => ({ x: d.date, y: d.currSum ? d.currSum / 1000 : undefined })).filter(isDataPoint)
    },
    showGoals && {
      name: `FY${fy} Goal`,
      color: colours.lightBlue2,
      data: data.map(d => ({
        x: new Date(d.readableDate),
        y: Number.isFinite(d.goal) ? (d.goal as number) / 1000 : NaN
      }))
    },
    showGoals && {
      name: `FY${fy + 1} Goal`,
      color: colours.lightBlue6,
      data: data.map(d => ({
        x: new Date(d.readableDate),
        y: Number.isFinite(d.goalNextFy) && typeof d.goalNextFy === 'number' ? d.goalNextFy / 1000 : NaN
      }))
    }
  ].filter((s): s is Serie => s !== false)
}

const formatGoal = (goal: number, func: SiteFunction[]) => {
  const withCommonAreas = func.some(item => item === 'Common Areas') && func.every(item => item !== 'Tenants')
  const withTenants = func.some(item => item === 'Tenants') && func.every(item => item !== 'Common Areas')

  if (goal === 0 && (withCommonAreas || withTenants)) {
    return 'N/A'
  }

  if (!goal && goal !== 0) return 'N/A'

  return formatAbsoluteNumber(goal)
}
