import React from 'react'
import { GridHr } from './GridHelpers'
import { ResultNumber } from './ResultNumber'

export interface ResultsWithState {
  previousFy: number | null
  comparisonValue: number | null
  goal: number | null
  goalNextFy: number | null
}

export interface LocationResults extends ResultsWithState {
  locationName: string
  selectable: boolean
}

interface SiteResultsTableProps {
  results: LocationResults[]
  className: string
  minimumDigits: number
  formatNumber?: (x: number) => string
}

export const SiteResultsTable: React.FC<SiteResultsTableProps> = ({ results, minimumDigits, formatNumber }) => {
  return (
    <>
      {results.map(result => (
        <React.Fragment key={result.locationName}>
          <SiteHr />
          <div className={`Site`}>{result.locationName}</div>
          <ResultNumber
            className="ResultPrevFyValue"
            value={result.previousFy}
            minimumDigits={minimumDigits}
            formatNumber={formatNumber}
          />
          <ResultNumber
            className="ComparisonValue"
            value={result.comparisonValue ?? NaN}
            minimumDigits={minimumDigits}
            formatNumber={formatNumber}
          />
          <ResultNumber
            className="GoalValue"
            value={result.goal}
            minimumDigits={minimumDigits}
            formatNumber={formatNumber}
          />
        </React.Fragment>
      ))}
    </>
  )
}

const SiteHr: React.FC = () => <GridHr className="Site" />
