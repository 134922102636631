import React from 'react'
import { ChartContainer, isDataPoint } from '../../../components/BaseGraphs/ChartContainer'
import { stackedBarChart } from '../../../components/BaseGraphs/GraphUtil'
import { isArray } from 'lodash'
import colours from '../../../Colours.module.scss'
import { CustomerTravelContext } from './CustomerTravelProvider'
import { CustomerTravelFootprint } from '../../../../api/src/types/climate'
import { formatAbsoluteNumber, formatRelativeNumber } from '../../../components/Utils/format'
import { SumIndicator } from '../../../components/BaseGraphs/Indicators'

const prevFYDomainDay = 2
const currFYDomainDay = 16
const emptyDomain = 28

export const toDomainDay = (year: number, domainDay: number) => (footprint: CustomerTravelFootprint) => {
  const date = new Date(footprint.readableDate)
  date.setDate(domainDay)
  date.setFullYear(year)

  return {
    ...footprint,
    date
  }
}

export const FootprintByTransportTypeKPI = () => {
  const { footprint, dates } = React.useContext(CustomerTravelContext)

  const currentFY = footprint[footprint?.length - 1]?.fiscalYear

  const dataPrevFy = footprint?.map(toDomainDay(currentFY + 2000, prevFYDomainDay))
  const dataCurrFy = footprint?.map(toDomainDay(currentFY + 2000, currFYDomainDay))

  const dataMoreThenTen = React.useMemo(() => {
    return Boolean(
      footprint.find(
        item =>
          item.curr_footprint_car / 1000 > 10 ||
          item.curr_footprint_public / 1000 > 10 ||
          item.curr_footprint_moto / 1000 > 10 ||
          item.prev_footprint_car / 1000 > 10 ||
          item.prev_footprint_public / 1000 > 10 ||
          item.prev_footprint_moto / 1000 > 10
      )
    )
  }, [footprint])

  const formatData = React.useCallback(
    (value: number) => {
      return dataMoreThenTen ? Math.round(value / 1000) : Math.round(value / 10) / 100
    },
    [dataMoreThenTen]
  )

  const getTooltipSum = React.useCallback(
    (date: Date) => {
      if (!footprint || !dataCurrFy) {
        return []
      }

      const prefix = date.getDate() === prevFYDomainDay ? 'prev' : 'curr'

      const data = dataCurrFy.find(d => new Date(d.readableDate).getMonth() === date.getMonth())

      const sum = data
        ? (data[`${prefix}_footprint_moto`] ?? 0) +
          (data[`${prefix}_footprint_car`] ?? 0) +
          (data[`${prefix}_footprint_public`] ?? 0)
        : 0

      const value = sum / 1000 < 10 ? formatRelativeNumber(formatData(sum)) : formatAbsoluteNumber(formatData(sum))

      return [{ title: 'Total', value, icon: <SumIndicator /> }]
    },
    [footprint, prevFYDomainDay, dataCurrFy]
  )

  if (footprint.length === 0) return null

  const series = !isArray(footprint)
    ? undefined
    : footprint.length === 0
    ? []
    : [
        {
          name: `Car FY${currentFY}`,
          color: colours.lightBlue11,
          data: dataCurrFy
            .map(d => ({
              x: d.date,
              y: formatData(d.curr_footprint_car)
            }))
            .filter(isDataPoint)
        },
        {
          name: `Public transport FY${currentFY}`,
          color: colours.blue9,
          data: dataCurrFy
            .map(d => ({
              x: d.date,
              y: formatData(d.curr_footprint_public)
            }))
            .filter(isDataPoint)
        },
        {
          name: `Motorcycle FY${currentFY}`,
          color: colours.orange3,
          data: dataCurrFy
            .map(d => ({
              x: d.date,
              y: formatData(d.curr_footprint_moto)
            }))
            .filter(isDataPoint)
        },
        {
          name: `Car FY${currentFY - 1}`,
          color: colours.lightBlue9,
          data: dataPrevFy
            .map(d => ({
              x: d.date,
              y: formatData(d.prev_footprint_car)
            }))
            .filter(isDataPoint)
        },
        {
          name: `Public transport FY${currentFY - 1}`,
          color: colours.lightBlue10,
          data: dataPrevFy
            .map(d => ({
              x: d.date,
              y: formatData(d.prev_footprint_public)
            }))
            .filter(isDataPoint)
        },
        {
          name: `Motorcycle FY${currentFY - 1}`,
          color: colours.orange4,
          data: dataPrevFy
            .map(d => ({
              x: d.date,
              y: formatData(d.prev_footprint_moto)
            }))
            .filter(isDataPoint)
        }
      ]

  const domain = dates?.reduce((result, current) => {
    const date = new Date(current).setFullYear(currentFY + 2000)

    const dateFirst = new Date(date)
    const dateSecond = new Date(date)
    const dateEmpty = new Date(date)
    dateFirst.setDate(prevFYDomainDay)
    dateSecond.setDate(currFYDomainDay)
    dateEmpty.setDate(emptyDomain)

    return [...result, new Date(dateFirst), new Date(dateSecond), new Date(dateEmpty)]
  }, [] as Date[])

  const [footprintSum, footprintRecordsCount] = footprint.reduce(
    (result, current) => {
      if (!current.curr_footprint_car) return result
      return [result[0] + current.curr_footprint_car, result[1] + 1]
    },
    [0, 0]
  )

  return (
    <div className="CardContainer">
      <div className="CardHeading FirstItem">
        Climate Footprint by Transport Type
        <div className="Right">
          <span className="Amount">{formatRelativeNumber(footprintSum / footprintRecordsCount / 1000)}</span>{' '}
          <span className="Unit">t CO2e/month avg. from cars YTD</span>
        </div>
      </div>
      <ChartContainer
        domain={domain}
        series={series}
        generator={stackedBarChart('none')}
        dateFormat="monthTwoFYs"
        tooltipSummary={date => getTooltipSum(date)}
        yAxisTitle="tonnes CO2e"
        isSmallGraph
        withDynamicFormatting
      />
    </div>
  )
}
