import React from 'react'

export const KpiCustomerDeliveriesModalContent = () => (
  <>
    <p>
      Climate Footprint of Customer Deliveries KPI shows the impact that Ingka&#39;s deliveries to customers have on
      climate change.
    </p>
    <p>
      In FY23 the scope of the KPI is the footprint of last mile home deliveries done by truck, representing high share
      of the total climate footprint of customer deliveries. Other delivery flows, such as parcel deliveries,
      trunkline/linehaul, returns etc. will be added at a later stage, step by step until all delivery flows are
      included.
    </p>
    <h3>How this is calculated</h3>
    <p>We calculate climate impact of customer deliveries (in tonnes CO2e) with the following high-level formula:</p>
    <p>
      Total tonnes CO2e = Distance (km) * Fuel consumption (L/km, kWh/km) * Emission factor (kg CO2e/L, kg CO2e/kWh)
    </p>
    <p>
      Distance is calculated by estimating routes, using country specific operational set-up. Estimated distance is then
      combined with fuel consumption of vehicle and fuel type used in each country and corresponding emission factor to
      calculate total CO2e emissions.
    </p>
    <h3>Why is it measured in Carbon Dioxide Equivalent (CO2e)?</h3>
    <p>
      Carbon dioxide equivalent or (CO2e) is a term for describing different greenhouse gases in a common unit. It is a
      standard unit for measuring carbon footprints and the best way to quantify our impact on climate change.
    </p>
    <h3>FY23 Goal</h3>
    <p>
      The goal is set based on forecasted number of last mile home deliveries done by truck and the Zero Emissions
      Delivery share commitment for FY23.
    </p>

    <h3>Definitions of the three smaller graphs:</h3>
    <p />
    <ul>
      <li>
        <b>Zero Emission Delivery Share:</b>{' '}
        <div>% share of total deliveries in last mile performed with a zero-emission vehicle.</div>
      </li>
      <li>
        <b>Emissions per order:</b> <div>kg CO2e/order. Total kg CO2e divided by total number of orders.</div>
      </li>
      <li>
        <b>Emissions per km:</b> <div>g CO2e/km. Total kg CO2e divided by total number of km.</div>
      </li>
    </ul>
    <h3>Where is the data coming from? How often it is updated?</h3>
    <p>Data is uploaded on a monthly basis. The raw data includes:</p>
    <ul>
      <li>Transactional order data from Centiro</li>
      <li>EV/ZE flag data from Centiro</li>
      <li>Country conditions are provided by CFF ZE PIM in each country </li>
      <li>Emission factors from GLEC, IEA & DEFRA</li>
    </ul>
    <p>Raw data is calculated in a data product in the Digital DSM domain.</p>
    <h3>How to contribute to this KPI?</h3>
    <p>There are many ways to contribute to this KPI, by choosing or influencing the following:</p>
    <ul>
      <li>Type of fleet used (EV vehicles etc.)</li>
      <li>Service offer to customers (range of days vs specific time slot) </li>
      <li>Operational set-up (mix of orders of different service types and timeslots) </li>
      <li>Efficiency in delivery operations </li>
      <li>Delivery optimization </li>
    </ul>
  </>
)
