import React from 'react'

import { useLocations } from '../../context'
import { ArrowIcon } from '../ArrowIcon'
import { getCluster, getLocationOrDefault } from '../Utils/utils'
import { ResultNumber } from './ResultNumber'
import { SiteResultsTable, ResultsWithState, LocationResults } from './SiteResultsTable'
import { ReactComponent as InformationIndicator } from '../../images/Icons/InformationIndicator.svg'
import { Tooltip } from '../../components/Tooltip'
import colours from '../../Colours.module.scss'

export enum TableRowKey {
  PlanetFootprint = 'planetfootprint',
  FoodIngredients = 'foodingredients',
  PlanetRecycling = 'planetrecycling',
  PppSales = 'pppsales',
  Perception = 'perception',
  SocialImpact = 'socialimpact',
  ChangeMakers = 'changemakers',
  ZeroWaste = 'zerowaste',
  EnergyEfficiency = 'energyefficiency',
  WaterEfficiency = 'waterefficiency',
  CustomerDeliveries = 'customerdeliveries'
}

export const kpiNames: Record<TableRowKey, string> = {
  [TableRowKey.PlanetFootprint]: 'Climate Footprint',
  [TableRowKey.PlanetRecycling]: 'Recycling rate',
  [TableRowKey.PppSales]: 'People+Planet Positive Sales',
  [TableRowKey.Perception]: 'Sustainability Mentions',
  [TableRowKey.SocialImpact]: 'Social Impact',
  [TableRowKey.ChangeMakers]: 'Change Makers',
  [TableRowKey.ZeroWaste]: 'Zero Waste',
  [TableRowKey.EnergyEfficiency]: 'Energy Efficiency',
  [TableRowKey.WaterEfficiency]: 'Water Efficiency',
  [TableRowKey.FoodIngredients]: 'Food Ingredients Sold',
  [TableRowKey.CustomerDeliveries]: 'Customer Deliveries'
}

interface BenchmarkTableRowProps {
  kpiUnits: string
  locationResults: ResultsWithState
  childLocationResults: LocationResults[]
  rowKey: TableRowKey
  expandedKey?: TableRowKey

  toggleExpanded(key: TableRowKey): void

  formatNumber?: (x: number) => string
  tooltip?: string
}

const isContributing = ({ comparisonValue, previousFy }: LocationResults) =>
  isFinite(comparisonValue ?? NaN) || isFinite(previousFy ?? NaN)
const isSelectable = ({ selectable }: LocationResults) => selectable

export const BenchmarkTableRow: React.FC<BenchmarkTableRowProps> = ({
  kpiUnits,
  locationResults,
  childLocationResults,
  rowKey,
  expandedKey,
  toggleExpanded,
  formatNumber,
  tooltip
}) => {
  const { clusters, currentLocation } = useLocations()
  const locationId = getLocationOrDefault()
  const { previousFy, comparisonValue, goal, goalNextFy } = locationResults
  const onToggleExpanded = () => toggleExpanded(rowKey)
  const isExpanded = expandedKey === rowKey
  const minimumDigits =
    rowKey === TableRowKey.PlanetFootprint
      ? 2
      : rowKey === TableRowKey.PppSales || rowKey === TableRowKey.Perception
      ? 1
      : 0

  const childLocationResultsToShow = childLocationResults
    .filter(isSelectable)
    .filter(isContributing)
    .filter(x => !currentLocation.isCluster && x.locationName !== currentLocation.location.label)

  const locationLabel = !currentLocation.isCluster
    ? currentLocation.location.label
    : getCluster(clusters, locationId)?.clusterName

  return (
    <>
      {childLocationResultsToShow.length > 0 && (
        <div className="ExpandButton" onClick={onToggleExpanded}>
          <ArrowIcon angle={isExpanded ? 0 : 180} color={colours.offWhite1} />
        </div>
      )}
      <div className={`KpiGroup ${rowKey}`}>
        <h6>{kpiNames[rowKey]}</h6>
        <div className="KpiUnits">
          {kpiUnits}
          {tooltip && (
            <Tooltip tooltipText={tooltip} className="IconWrapper">
              <InformationIndicator className="Icon" fill={undefined} />
            </Tooltip>
          )}
        </div>
      </div>
      <div className="TopLevelSiteCountry">{locationLabel}</div>
      <ResultNumber
        className="ResultPrevFyValue"
        value={previousFy}
        minimumDigits={minimumDigits}
        formatNumber={formatNumber}
      />
      <ResultNumber
        className={`ComparisonValue ${rowKey}`}
        value={comparisonValue ?? NaN}
        minimumDigits={minimumDigits}
        formatNumber={formatNumber}
      />
      <ResultNumber
        className={`GoalValue ${rowKey}`}
        value={goal}
        minimumDigits={minimumDigits}
        formatNumber={formatNumber}
      />
      <ResultNumber
        className={`GoalNextValue ${rowKey}`}
        value={goalNextFy}
        minimumDigits={minimumDigits}
        formatNumber={formatNumber}
      />
      {isExpanded ? (
        <SiteResultsTable
          className={rowKey}
          results={childLocationResultsToShow}
          minimumDigits={minimumDigits}
          formatNumber={formatNumber}
        />
      ) : null}
    </>
  )
}
