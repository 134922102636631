import React from 'react'
import { capitalize } from 'lodash'
import SkapaModal, { ModalBody, ModalHeader, Sheets } from '@ingka/modal'

import { ReactComponent as MenuIcon } from '../images/Icons/NavigationMenu.svg'
import { ReactComponent as ScreenIcon } from '../images/Icons/Screen.svg'
import './Navigation.scss'
import { useSharedSelections } from '../SharedSelections'
import classNames from 'classnames'
import { Link } from './Link'
import { InformationIndicator } from './BaseGraphs/Indicators'
import { PortalWrapper } from './Utils/PortalWrapper'
import { FunctionArea } from '../../api/src/common-types'
import { useLocations } from '../context'
import { Route } from '../routes'
import { createNewSelections } from './Utils/navigation'
import { getLocationId } from './Utils/utils'
import { useCustomerDeliveryData } from '../pages/KPIPages/CustomerDeliveries/CustomerDeliveriesKPIPage'
import { Tooltip } from './Tooltip'

const functionAreas: FunctionArea[] = ['retail', 'centres', 'ingka']

export const Navigation = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [isOpenFunctionArea, setIsOpenFunctionArea] = React.useState(false)
  const [{ functionArea }, updateSharedSelections] = useSharedSelections()
  const { currentLocation } = useLocations()
  const locationId = getLocationId(currentLocation)
  const closeBoth = () => {
    setIsOpen(false)
    setIsOpenFunctionArea(false)
  }
  const { currFy, prevFy } = useCustomerDeliveryData(locationId)
  const isNoDataDeliveries =
    currFy.filter(i => i.deliveries !== null).length === 0 && prevFy.filter(i => i.deliveries !== null).length === 0

  if (functionArea) {
    sessionStorage.setItem('functionArea', functionArea)
  }

  return (
    <>
      <div className="NavigationIcon" onClick={() => setIsOpen(true)}>
        <MenuIcon />
      </div>
      <PortalWrapper>
        <SkapaModal visible={isOpen} handleCloseBtn={closeBoth}>
          <Sheets
            alignment="left"
            size="small"
            header={<ModalHeader floating className="SkapaModalHeader" />}
            footer={null}
          >
            <ModalBody className="NavigationModal">
              <div className={classNames('NavigationHeader', functionArea)}>
                Sustainability Dashboard – <span className="Selected">{capitalize(functionArea)}</span>{' '}
                <span className="Change" onClick={() => setIsOpenFunctionArea(true)}>
                  Change
                </span>
                {isOpenFunctionArea && (
                  <div className="FunctionAreaMenu">
                    {functionAreas.map(functionAreaOption => (
                      <div
                        key={functionAreaOption}
                        className={classNames('FunctionAreaOption', {
                          SelectedOption: functionArea === functionAreaOption
                        })}
                        onClick={() => {
                          closeBoth()
                          updateSharedSelections(createNewSelections(functionAreaOption, currentLocation))
                        }}
                      >
                        {capitalize(functionAreaOption)}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <Link page={Route.SnapshotPage} className="TopLevelLink">
                Snapshot
              </Link>
              <div className="KpiPages">
                {functionArea !== 'centres' && (
                  <>
                    <div className="Label">Healthy &amp; Sustainable Living</div>
                    <div />
                    <Link page={Route.PppSalesKPIPage}>People + Planet Positive Sales</Link>
                  </>
                )}
                <div className="Label">Circular</div>
                <Link page={Route.ZeroWasteKPIPage}>Zero Waste</Link>
                <div className="Label">Climate Positive</div>
                <Link page={Route.ClimateKPIPage}>Operational Climate Footprint</Link>
                {['retail', 'ingka'].includes(functionArea) && (
                  <>
                    <div />
                    <Link page={Route.FoodIngredientsKPIPage}>Climate Footprint — Food Ingredients</Link>
                    <div />
                    <div>
                      <Link page={Route.EnergyEfficiencyKPIPage}>Climate Footprint — Energy Efficiency</Link>
                    </div>
                    {!isNoDataDeliveries && (
                      <Link page={Route.CustomerDeliveriesKPIPage}>Climate Footprint — Customer Deliveries</Link>
                    )}
                  </>
                )}
                <div>
                  <Link page={Route.CustomerTravelKPIPage}>
                    <BetaLabel /> <span className="NavItemText">Climate Footprint — Customer Travel</span>
                  </Link>
                </div>
                <div>
                  <Link page={Route.WaterEfficiencyKPIPage}>
                    <span className="NavItemText">Water Efficiency</span>
                  </Link>
                </div>
                <div className="Label">Fair &amp; Equal</div>
                <Link page={Route.SocialImpactKPIPage}>Social Impact</Link>
                <div />
                <Link page={Route.ChangeMakersKPIPage}>Change Makers</Link>
              </div>
              {functionArea !== 'centres' && (
                <Link page={Route.LeaderboardPage} className="TopLevelLink">
                  Leaderboard
                </Link>
              )}
              <div className="NavigationFooter">
                <Link page={Route.Root}>
                  <InformationIndicator /> Instructions
                </Link>
                <Link page={Route.InstoreConfiguratorPage}>
                  <ScreenIcon /> Co-Worker screens
                </Link>
              </div>
            </ModalBody>
          </Sheets>
        </SkapaModal>
      </PortalWrapper>
    </>
  )
}

export const BetaLabel = () => {
  return (
    <Tooltip tooltipText="BETA KPI. Data may be limited or not mature" className="IconWrapper">
      <div className="BetaLabel">BETA</div>
    </Tooltip>
  )
}
