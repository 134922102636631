import React, { Fragment } from 'react'
import { round, floor, flatMap, chain, extend, toFinite, sum, flatten } from 'lodash'
import { formatAbsoluteNumber } from '../Utils/format'
import { format } from 'date-fns'
import { ScaleLinear } from 'd3-scale'
import {
  area,
  curveMonotoneX,
  line,
  Series,
  stack,
  stackOffsetNone,
  stackOrderDescending,
  stackOrderNone,
  stackOrderReverse
} from 'd3-shape'
import classNames from 'classnames'
import { datefnsLocale } from '../../Localisation'
import { isSameDay } from 'date-fns/esm'
import { parseFY } from '../Utils/utils'
import { GeneratorProps, Serie } from './ChartContainer'
import colours from '../../Colours.module.scss'
import { DataPoint } from './ChartContainer'
import { Show } from '../Conditions/Show'
import { formatNumberValue } from './Tooltip'

const horizontalGuideCount = 5

export type DateFormat =
  | 'week'
  | 'monthWithYear'
  | 'monthWithYearFy'
  | 'month'
  | 'monthName'
  | 'fy'
  | 'text'
  | 'monthTwoFYs'

export function calculateHorizontalGuides(
  yScale: ScaleLinear<number, number>,
  graphWidth: number,
  yAxisLabelWidth: number,
  margin: number,
  hideGuides: boolean,
  YAxisTitle?: string,
  withDynamicFormatting?: boolean
) {
  return yScale.ticks(horizontalGuideCount).map(value => {
    const y = yScale(value)
    return (
      <g key={`HorizontalGuide${value}`}>
        <text
          className="YAxisValue"
          x={yAxisLabelWidth}
          y={y}
          fontFamily='"Noto Sans", sans-serif'
          fontSize="10px"
          alignmentBaseline="middle"
          textAnchor="end"
        >
          {(value * 100) % 10 === 0 ? formatNumberValue(value, Boolean(withDynamicFormatting), '') : null}
        </text>
        {!hideGuides && (
          <line
            className="HorizontalGuide"
            x1={yAxisLabelWidth + margin / 2}
            y1={y}
            x2={graphWidth}
            y2={y}
            stroke={colours.offWhite5}
            strokeWidth={1}
          />
        )}
      </g>
    )
  })
}

export function calculateVerticalGuides(
  graphHeight: number,
  maxValue: number,
  calculateX: ScaleLinear<number, number, never>
) {
  const minNumberOfGuides = 4
  const margin = 4
  const maxNumberLength = Math.round(maxValue / minNumberOfGuides).toString().length
  const verticalGuideStep = Math.max(floor(maxValue / minNumberOfGuides, maxValue <= 5 ? 0 : 1 - maxNumberLength), 1)
  const verticalGuideXs = Array.from(Array(Math.max(floor(maxValue / verticalGuideStep) + 1, 4)).keys()).map(i =>
    i === 0 ? 350 : calculateX(i * verticalGuideStep)
  )

  return verticalGuideXs.map((x, i) => (
    <g key={`${i}VerticalGuide`}>
      {Number.isFinite(x) && (
        <>
          <text className="xAxisTitle" x={x} y={0} fontSize="10px" alignmentBaseline="middle" textAnchor="middle">
            {formatAbsoluteNumber(round(i * verticalGuideStep, 1))}
          </text>
          <line
            x1={x}
            className="xAxisStroke"
            y1={margin * 2}
            x2={x}
            y2={margin * 2 + graphHeight + margin}
            stroke={colours.offWhite2}
            strokeWidth={1}
          />
        </>
      )}
    </g>
  ))
}

export function sortSeriesByBiggestContributor(series: Serie[]) {
  return [...series]
    .sort((lhs, rhs) => {
      const lSum = lhs.data.reduce((sum, d) => sum + (d.y ?? 0), 0)
      const rSum = rhs.data.reduce((sum, d) => sum + (d.y ?? 0), 0)
      return rSum - lSum
    })
    .map((d, i) => ({ ...d, zIndex: i }))
}

export function sortSeriesByName(series?: Serie[]): Serie[] {
  return series
    ? [...series].sort((lhs, rhs) => {
        return rhs.name < lhs.name ? 1 : -1
      })
    : []
}

export function formatDate(d: Date | string, dateFormat: DateFormat, lang: string) {
  if (dateFormat === 'text') {
    return d.toString()
  }
  if (dateFormat === 'fy') {
    if (typeof d === 'string') {
      return `FY${d}`
    }
    return `FY${parseFY(d)}`
  }
  const date = new Date(d)
  const locale = datefnsLocale(lang)
  switch (dateFormat) {
    case 'monthTwoFYs':
    case 'month':
      return format(date, 'MMM', { locale })
    case 'monthWithYear':
      return format(date, 'MMM yy', { locale })
    case 'monthName':
      return format(date, 'MMMM', { locale })
    case 'week':
      return format(date, 'I/RRRR', { locale })
  }
}

function barPath(
  x: number,
  barWidth: number,
  barHeight: number,
  graphHeight: number,
  key: string,
  index: number,
  seriesLength: number,
  color?: string
) {
  const radius = calculateRadius(barWidth)
  return (
    <path
      key={key}
      className="VerticalBarPart"
      d={`M${x + (seriesLength > 1 ? index * barWidth : 0)},${barHeight} h${
        barWidth - radius
      } q${radius},0 ${radius},${radius} v${Math.max(graphHeight - barHeight - radius, 0)} h-${barWidth} v-${Math.max(
        graphHeight - barHeight - radius,
        0
      )} q0,-${radius} ${radius},-${radius}`}
      fill={color}
    />
  )
}

function overlayPath(
  x: number,
  barWidth: number,
  barHeight: number,
  _graphHeight: number,
  key: string,
  _index: number,
  _seriesLength: number,
  color?: string
) {
  const overlayWidth = barWidth * 1.2
  return (
    <rect
      key={`overlay${key}`}
      width={overlayWidth}
      height={2}
      x={x - (overlayWidth - barWidth) / 2}
      y={barHeight}
      fill={color}
    />
  )
}

function calculateRadius(barWidth: number) {
  if (barWidth < 16) {
    return 0
  } else if (barWidth < 32) {
    return 2
  }
  return 4
}

export function lineChart({
  domain,
  series,
  xScale,
  yScale,
  highlightedSerie,
  maxValue,
  isBoldGraphLine
}: GeneratorProps) {
  const max = maxValue || Math.max(...flatMap(series, s => flatMap(s.data, d => d.y)).filter(Number.isFinite))
  yScale.domain([0, Number.isFinite(max) ? max : 1]).nice(horizontalGuideCount)
  const getLineGenerator = (id: string | undefined, startAtYAxis = false, yAxisValue = 0) =>
    line<{ x: Date; y: number | undefined }>()
      .x(d => xScale(d.x) ?? 0)
      .y((d, i) => {
        if (i === 0 && id === 'goal' && startAtYAxis) {
          return yScale(yAxisValue)
        }
        return yScale(d.y ?? 0)
      })
      .curve(curveMonotoneX)
      .defined(d => Number.isFinite(d.y))
  const areaGenerator = area<{ x: Date; y: number | undefined }>()
    .x(d => xScale(d.x) ?? 0)
    .y0(_ => yScale.range()[0])
    .y1(d => yScale(d.y ?? 0))
    .curve(curveMonotoneX)
    .defined(d => Number.isFinite(d.y))

  const lines = series
    .map(({ name, color, fill, data, id, startAtYAxis }, i) => {
      const dataForWholeDomain = domain.map(date => ({ x: date, y: data.find(d => isSameDay(d.x, date))?.y }))
      const line = getLineGenerator(id, startAtYAxis, data[1]?.y)(dataForWholeDomain)
      const fillArea = fill ? areaGenerator(dataForWholeDomain) : undefined
      const urlCompatibleName = name?.replaceAll(' ', '_')
      const element = (
        <Fragment key={i}>
          {fillArea && (
            <Fragment key={`${urlCompatibleName}Fragment`}>
              <defs key={`${urlCompatibleName}Gradient`} data-key={`${urlCompatibleName}Gradient`}>
                <linearGradient id={`${urlCompatibleName}Gradient`} x1="0" x2="0" y1="0" y2="1">
                  <stop offset="0%" stopColor={fill} stopOpacity="1" />
                  <stop offset="100%" stopColor={fill} stopOpacity="0" />
                </linearGradient>
              </defs>
              <path
                key={`${urlCompatibleName}Fill`}
                d={fillArea}
                stroke="none"
                fill={`url(#${urlCompatibleName}Gradient)`}
              />
            </Fragment>
          )}

          <path
            key={`${urlCompatibleName}Line`}
            d={line ?? undefined}
            stroke={color}
            strokeWidth={isBoldGraphLine ? 5 : highlightedSerie === name ? 4 : 2}
            fill="none"
            opacity={isBoldGraphLine ? 1.25 : highlightedSerie && highlightedSerie !== name ? 0.5 : 1}
            strokeLinecap="round"
          />
        </Fragment>
      )
      return { element, fill: fillArea !== undefined }
    })
    .sort((a, b) => (a.fill === b.fill ? 0 : a.fill ? -1 : 1))
    .map(el => el.element)

  return lines
}

export function stackedLineChart({ domain, series, xScale, yScale, maxValue }: GeneratorProps) {
  const serieNames = series.map(serie => serie.name)

  const data = chain(series)
    .flatMap(serie => serie.data.map(({ x, y }) => ({ date: x, [serie.name]: y })))
    .groupBy('date')
    .values()
    .map(records => chain(records).reduce(extend).value() as Record<'date', Date> & Record<string, number>)
    .value()

  const dataMaxValue = chain(data)
    .flatMap(record => chain(record).pick(serieNames).values().sum().value())
    .max()
    .value()

  const max = toFinite(maxValue ?? dataMaxValue ?? 0)
  yScale.domain([0, max]).nice(horizontalGuideCount)

  const stackGenerator = stack().keys(serieNames).offset(stackOffsetNone).order(stackOrderDescending)
  const stackData = stackGenerator(data)

  const areaGenerator = area()
    .x((_datapoint, idx) => xScale(domain[idx]) ?? 0)
    .y0(([lo, _hi]) => yScale(lo))
    .y1(([_lo, hi]) => yScale(hi))
    .curve(curveMonotoneX)

  return chain(series)
    .flatMap(({ name, color }) => {
      const data = stackData.find(d => d.key === name)
      const area = areaGenerator(data as [number, number][])

      if (!area) return []

      return <path key={`${name}Path`} d={area} stroke={color} fill={color} />
    })
    .value()
}

export function barChart({ domain, series, xScale, yScale }: GeneratorProps) {
  const max = Math.max(
    ...flatMap(series, s => [...flatMap(s.data, d => d.y), ...(flatMap(s.secondaryData, d => d.y) ?? [])]).filter(
      Number.isFinite
    )
  )
  yScale.domain([0, Number.isFinite(max) ? max : 1]).nice(horizontalGuideCount)
  const barWidth = xScale.bandwidth() / series.length
  const graphId = Math.round(Math.random() * 1000)
  const bars = flatMap(series, (serie, i) => {
    return flatMap(domain, (date, j) => {
      const x = xScale(date) ?? 0
      const value = serie.data.find(d => isSameDay(d.x, date))?.y
      const secondaryValue = serie.secondaryData?.find(d => isSameDay(d.x, date))?.y
      const primary = value
        ? barPath(x, barWidth, yScale(value), yScale.range()[0], `${graphId}-${i}-${j}`, i, series.length, serie.color)
        : undefined
      const secondary = secondaryValue
        ? primary
          ? overlayPath(
              x,
              barWidth,
              yScale(secondaryValue),
              0,
              `${graphId}-${i}-${j}-secondary`,
              0,
              0,
              serie.secondaryColor ?? 'black'
            )
          : barPath(
              x,
              barWidth,
              yScale(secondaryValue),
              yScale.range()[0],
              `${graphId}-${i}-${j}-secondary`,
              i,
              series.length,
              serie.secondaryColor ?? 'black'
            )
        : undefined

      return [...(primary ? [primary] : []), ...(secondary ? [secondary] : [])]
    })
  })
  return bars
}

function getLowerDomainValue(series: Serie[]): number {
  return series.reduce((result, current): number => {
    const lowerValue = current.data.find(item => {
      const startPoint = item.startPoint || 0

      return startPoint < result
    })

    return lowerValue?.y || result
  }, 0)
}

export function stackedBarChart(
  order: 'none' | 'descending' | 'reverse',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customOrdering?: (series: Series<any, any>[]) => Iterable<number>
) {
  return function ({ domain, series: seriesWithActions, xScale, yScale, maxValue }: GeneratorProps) {
    const series = seriesWithActions.filter(s => s.name !== 'Planned activities')
    const lowerDomainValue = getLowerDomainValue(seriesWithActions)

    const dataByDate = domain.map(date => {
      return series.reduce(
        (acc, serie) => {
          const serieValue = serie.data.find(d => d.x.getTime() === date.getTime())?.y ?? 0
          const sumOtherSeries = sum(
            series
              .filter(other => other.name !== serie.name)
              .map(other => other.data.find(d => d.x.getTime() === date.getTime())?.y ?? 0)
          )
          return {
            ...acc,
            [serie.name]: !serie.isBackgroundSerie
              ? serieValue
              : // We show as a bar only those background series, which have a greater value than other series summed up.
              // The other case is handled below. In that case, we'll show a tick on top of the stacked bars.
              serieValue > sumOtherSeries
              ? serieValue - sumOtherSeries
              : NaN
          }
        },
        { readableDate: date }
      )
    })

    const ordering = (() => {
      // Type error inside li
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (customOrdering) return customOrdering as any

      switch (order) {
        case 'descending':
          return stackOrderDescending

        case 'reverse':
          return stackOrderReverse

        default:
          return stackOrderNone
      }
    })()

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const stackGenerator = stack<any, any, any>()
      .keys(series.map(s => s.name))
      .order(ordering)
      .offset(stackOffsetNone)
    const stackSeries = stackGenerator(dataByDate)
    const max =
      maxValue ?? Math.max(...flatMap(stackSeries, stack => flatMap(stack, s => [s[0], s[1]])).filter(Number.isFinite))
    yScale.domain([lowerDomainValue, Number.isFinite(max) ? max : 1]).nice(horizontalGuideCount)

    const graphId = Math.round(Math.random() * 1000)
    const bars = flatMap(stackSeries, (stack, i) => {
      const color = series.find(s => s.name === stack.key)?.color
      const seriesGoalData = color === 'none' ? series.find(s => s.name === stack.key)?.data : []
      return stack.map((datapoint, j) => {
        const x = xScale(datapoint.data.readableDate) ?? 0
        const barHeight = yScale(datapoint[0]) - yScale(datapoint[1])
        const barWidth = xScale.bandwidth()
        const goalColor = seriesGoalData?.find(s => s.x === datapoint.data.readableDate)?.color

        if (!(Number.isFinite(datapoint[0]) && Number.isFinite(datapoint[1]))) {
          return <path key={`${graphId}-${i}-${j}`} />
        }

        return (
          <path
            key={`${graphId}-${i}-${j}`}
            className={classNames('VerticalBarPart')}
            d={`M${x}, ${yScale(datapoint[0])} v${-barHeight} h${barWidth} v${barHeight} z`}
            fill={goalColor ? goalColor : color}
            clipPath={`url(#clipPath${graphId}${j})`}
          />
        )
      })
    })

    const backgroundSerieTicks = flatten(
      series
        .filter(serie => serie.isBackgroundSerie)
        .map(serie => {
          return serie.data
            .filter(datapoint => {
              const sumOtherSeries = sum(
                series
                  .filter(other => other.name !== serie.name)
                  .map(other => other.data.find(d => d.x.getTime() === datapoint.x.getTime())?.y ?? 0)
              )
              // We show a tick only for the background series that have smaller value that the non-background series
              // have summed up. The other case is handled above.
              return datapoint.y < sumOtherSeries
            })
            .map(datapoint => (
              <rect
                key={`${serie.name}-${String(datapoint.x)}`}
                width={xScale.bandwidth() + 6}
                height={4}
                rx={2}
                x={(xScale(datapoint.x) ?? 0) - 3}
                y={yScale(datapoint.y)}
                fill={serie.color === 'none' ? serie.data[0].color : serie.color}
                stroke="white"
              />
            ))
        })
    )

    const graphHeight = yScale.range()[0]
    const clipPaths =
      stackSeries.length > 0
        ? stackSeries[0].map((_, i) => {
            const x = xScale(stackSeries[0][i].data.readableDate) ?? 0
            const barHeight = yScale(Math.max(...stackSeries.map(x => x[i][1]).filter(Number.isFinite)))
            const barWidth = xScale.bandwidth()
            const path = barPath(x, barWidth, barHeight, graphHeight, `clipPath${graphId}${i}`, i, 1, undefined)

            return (
              <clipPath key={`clipPath${i}`} id={`clipPath${graphId}${i}`}>
                {path}
              </clipPath>
            )
          })
        : []

    const actionsBarsData =
      seriesWithActions
        .find(s => s.name === 'Planned activities')
        ?.data.sort((a, b) => a.x.getTime() - b.x.getTime())
        .reduce(
          (acc: DataPoint[], actionSerie, i) => [
            ...acc,
            {
              ...actionSerie,
              startPoint: actionSerie.startPoint ?? (acc[i - 1]?.startPoint ?? 0) + actionSerie.y
            }
          ],
          []
        ) ?? []

    const actionsBars = actionsBarsData.map(({ x, y, startPoint }, i) => {
      if (!startPoint) return <></>

      const xPath = Number(xScale(x))
      const barWidth = xScale.bandwidth()
      const barStep = xScale.step()
      const barHeight = yScale(startPoint) - yScale(startPoint + y)
      const isLast = actionsBarsData.length - 1 === i

      const hideDashedLine = i === 0 && isLast
      return (
        <>
          <path
            key={`${graphId}-action-${i}`}
            className={classNames('VerticalBarPart')}
            d={`M${xPath}, ${yScale(startPoint)} v${barHeight} h${barWidth} v${-barHeight} z`}
            fill={y < 0 ? 'url(#pattern)' : 'url(#orange-pattern)'}
          />
          <Show when={!hideDashedLine}>
            <line
              data-test={y}
              x1={xPath - barStep + barWidth}
              x2={Number(xPath) + barWidth}
              y1={yScale(startPoint - y)}
              y2={yScale(startPoint - y)}
              stroke="#00324A"
              strokeDasharray="2 2"
            />
          </Show>
          <Show when={Boolean(y)}>
            <path
              d={`M ${xPath}, ${yScale(startPoint - y)} h${barWidth} z`}
              stroke="black"
              strokeWidth={2}
              clipPath="url(#rounded-corner)"
            />
          </Show>
        </>
      )
    })

    return [...bars, ...actionsBars, ...clipPaths, ...backgroundSerieTicks]
  }
}
