import React from 'react'
import { groupBy, isEqual, sumBy, Dictionary, find, maxBy, range, flatten } from 'lodash'

import SocialImpactStyles from '../pages/KPIPages/SocialImpact/SocialImpactKPIPage.module.scss'
import ChangeMakersStyles from '../pages/KPIPages/ChangeMakers/ChangeMakersKPIPage.module.scss'
import colours from '../Colours.module.scss'

import * as Universal from './BaseGraphs/Universal'

import './GraphSideBarCards.scss'
import { LoadingSkeleton } from './LoadingSkeleton'
import { barChart, lineChart } from './BaseGraphs/GraphUtil'
import { NoDataViewSmall } from './BaseGraphs/NoDataView'
import { useSharedSelections } from '../SharedSelections'
import { ChartContainer, isDataPoint, Serie } from './BaseGraphs/ChartContainer'
import { formatRelativeNumber, formatAbsoluteNumber } from './Utils/format'
import {
  ChangeMakers,
  CustomerDeliveries,
  PeopleImpacted,
  WaterSmallCardData,
  YtdFootprintAndGoals
} from '../../api/src/common-types'
import { FinancialYearChartContainer } from './BaseGraphs/FinancialYearChartContainer'
import { getFinancialYear } from './Utils/dates'
import { DataPoint } from './BaseGraphs/ChartContainer'

import { ReactComponent as InformationIndicator } from '../images/Icons/InformationIndicator.svg'
import { Tooltip } from '../components/Tooltip'
import { PercentageDonutChart } from './DonutChart'
import classNames from 'classnames'
import _ from 'lodash'
import { endOfMonth } from 'date-fns'

interface SideBarCardsLoadingProps {
  cardCount?: number
  className?: string
}

export const SideBarCardsLoading: React.FC<SideBarCardsLoadingProps> = ({ className, cardCount = 3 }) => (
  <div className={className}>
    {range(0, cardCount).map(idx => (
      <div className="GraphSideBarCard" key={idx}>
        <LoadingSkeleton />
      </div>
    ))}
  </div>
)

export const SideBarCardsNoData: React.FC<SideBarCardsLoadingProps> = ({ className, cardCount = 3 }) => (
  <div className={className}>
    {range(0, cardCount).map(idx => (
      <div className="GraphSideBarCard" key={idx}>
        <NoDataViewSmall />
      </div>
    ))}
  </div>
)

interface PlanetFootprintGraphSideBarCardsProps {
  className: string
  graphData: YtdFootprintAndGoals[] | undefined
  dates: Date[] | undefined
  isOld?: boolean
}

interface SocialImpactGraphSideBarCardProps {
  peopleImpacted: PeopleImpacted[] | undefined
  currFyName: string
  actualPeriodDate?: string
}

interface ChangeMakersGraphSideBarCardsProps {
  changeMakers: (ChangeMakers & { date: Date })[]
  currFyName: string
}

interface CustomerDeliverGraphSideBarCardsProps {
  className: string
  graphData: Dictionary<CustomerDeliveries[]> | undefined
  locationId: string
  goal?: number
}

interface WaterGraphSideBarCardsProps {
  className: string
  graphData: Dictionary<WaterSmallCardData[]> | undefined
  isLoading: boolean
  isSite?: boolean
}

export function PlanetFootprintGraphSideBarCards({
  className,
  graphData,
  dates,
  isOld
}: PlanetFootprintGraphSideBarCardsProps) {
  const [{ func }] = useSharedSelections()

  if (!graphData || graphData.length === 0 || !dates) {
    return <SideBarCardsLoading className={className} />
  }

  const metadataForBoth = [
    {
      currentFYColor: Universal.colors[0],
      prevFYColor: Universal.fadedColors[0],
      title: `Energy`,
      key: 'energy' as const
    },
    {
      currentFYColor: Universal.colors[9],
      prevFYColor: Universal.fadedColors[3],
      title: `Refrigerants`,
      key: 'refrigerants' as const
    },
    {
      currentFYColor: Universal.colors[1],
      prevFYColor: Universal.fadedColors[1],
      title: `Waste`,
      key: 'waste' as const
    }
  ]

  const metadata = isOld
    ? [
        ...metadataForBoth,
        {
          currentFYColor: Universal.colors[10],
          prevFYColor: Universal.fadedColors[4],
          title: `Water`,
          key: 'water' as const
        }
      ]
    : metadataForBoth

  const denominator = isOld ? 1000 : 1

  const formatDataValues = (value: number, formatAsAbsolute: boolean) => {
    return formatAsAbsolute ? Math.round(value / denominator) : Math.round((value / denominator) * 100) / 100
  }

  const currentFY = Math.max(...graphData.map(d => d.fiscalYear))
  const currentFYName = `FY${currentFY}`
  const previousFYName = `FY${currentFY - 1}`
  const cards = metadata.map((md, i) => {
    const formatAsAbsolute = graphData.some(
      item =>
        Number(item[`${md.key}CurrentFY`]) / denominator > 10 || Number(item[`${md.key}PreviousFY`]) / denominator > 10
    )

    const currentFYSerie = {
      name: currentFYName,
      color: md.currentFYColor,
      data: graphData
        .map(d => ({ x: new Date(d.readableDate), y: d[`${md.key}CurrentFY`] }))
        .filter(isDataPoint)
        .map(d => ({ x: d.x, y: formatDataValues(d.y, formatAsAbsolute) })),
      secondaryName: previousFYName,
      secondaryColor: md.prevFYColor,
      secondaryData: graphData
        .map(d => ({ x: new Date(d.readableDate), y: d[`${md.key}PreviousFY`] }))
        .filter(isDataPoint)
        .map(d => ({ x: d.x, y: formatDataValues(d.y, formatAsAbsolute) }))
    }
    const ytd = graphData.reduce((acc, d) => acc + (d[`${md.key}CurrentFY`] ?? 0), 0)
    const isDataAvailable = currentFYSerie && currentFYSerie.data.length > 0 && isDataNonZero(currentFYSerie.data)

    return (
      <div key={`card${i}`} className="GraphSideBarCard">
        <div className="CardHeading">
          <h3>{md.title}</h3>
          <div>
            <span className="Amount">
              {formatAsAbsolute
                ? formatAbsoluteNumber(Math.round(ytd / denominator))
                : formatRelativeNumber(ytd / denominator)}
            </span>{' '}
            <span className="Unit">{`tonnes CO2e ${isOld ? 'YTD' : ''}`} </span>
          </div>
        </div>
        {md.key === 'waste' && isEqual(func, ['Tenants']) ? (
          <NoDataViewSmall />
        ) : isDataAvailable ? (
          <ChartContainer
            generator={barChart}
            domain={dates}
            series={[currentFYSerie]}
            dateFormat="month"
            disableLegendItems
            yAxisTitle="tonnes CO2e"
            yAxisLabelTooltipOnly
            withDynamicFormatting
          />
        ) : (
          <NoDataViewSmall />
        )}
      </div>
    )
  })

  return <div className={className}>{cards}</div>
}

const isDataNonZero = (data: DataPoint[]) => data.some(dataPoint => dataPoint.y !== 0)

export function SocialImpactGraphSideBarCards({
  peopleImpacted,
  currFyName,
  actualPeriodDate
}: SocialImpactGraphSideBarCardProps) {
  if (!peopleImpacted) return <SideBarCardsLoading cardCount={3} className="GraphRow" />

  const byFinancialYear = groupBy(peopleImpacted, ({ readableDate }) => getFinancialYear(readableDate))
  const currFyData = byFinancialYear[currFyName] ?? []

  const values = {
    total: sumBy(currFyData, 'total'),
    transformed: sumBy(currFyData, 'transformed'),
    improved: sumBy(currFyData, 'improved'),
    connected: sumBy(currFyData, 'connected')
  }

  const percentageSeries = [
    {
      name: 'Positively impacted',
      color: colours.yellow1,
      value: (values.transformed + values.improved) / values.total
    },
    {
      name: 'Connected',
      color: colours.purple1,
      value: values.connected / values.total
    }
  ]
  const percentageSeriesHasData =
    peopleImpacted.length > 0 && isFinite(percentageSeries[0].value) && isFinite(percentageSeries[1].value)

  const currFyCumulative = currFyData
    .filter(item => new Date(item.readableDate) <= new Date(actualPeriodDate || ''))
    .map((impact, index) => {
      const monthsSoFar = currFyData.slice(0, index + 1)
      return {
        ...impact,
        total: sumBy(monthsSoFar, 'total'),
        connected: sumBy(monthsSoFar, 'connected'),
        improved: sumBy(monthsSoFar, 'improved'),
        transformed: sumBy(monthsSoFar, 'transformed')
      }
    })

  const outcomeLevelSeries = [
    {
      id: 'transformed',
      name: 'Transformed',
      color: colours.salmon,
      data: currFyCumulative.map(({ readableDate, transformed }) => ({ x: new Date(readableDate), y: transformed }))
    },
    {
      id: 'improved',
      name: 'Improved',
      color: colours.pink1,
      data: currFyCumulative.map(({ readableDate, improved }) => ({ x: new Date(readableDate), y: improved }))
    },
    {
      id: 'connected',
      name: 'Connected',
      color: colours.purple1,
      data: currFyCumulative.map(({ readableDate, connected }) => ({ x: new Date(readableDate), y: connected }))
    }
  ]
  const outcomeLevelSeriesHasData = !flatten(outcomeLevelSeries.map(x => x.data.map(x => x.y))).every(x => x === 0)

  return (
    <div className={classNames('GraphRow', 'three-in-row')}>
      <div className="GraphSideBarCard">
        <div className="CardHeading">
          <h3>Supported people per outcome level</h3>
          <div>
            <span className="Unit">people</span>
          </div>
        </div>
        <FinancialYearChartContainer
          generator={lineChart}
          series={outcomeLevelSeries}
          noData={!outcomeLevelSeriesHasData}
          domainFromSerie="Transformed"
          isSmallGraph
          lineChartConfiguration={{
            startFromZero: true,
            focusStyle: 'none'
          }}
        />
      </div>
      <div className="GraphSideBarCard">
        <div className="CardHeading">
          <h3>People supported</h3>
          <div>
            <span className="Amount">{formatAbsoluteNumber(values.total)}</span>{' '}
            <span className="Unit">people YTD</span>
          </div>
        </div>
        {percentageSeriesHasData ? (
          <div className={SocialImpactStyles.StatsCardContainer}>
            <div className={SocialImpactStyles.StatsRow} data-testid="social-impact-transformed-total">
              <span className={SocialImpactStyles.Dot} style={{ backgroundColor: colours.salmon }} />
              <span className={SocialImpactStyles.Title}>Transformed</span>
              <span className={SocialImpactStyles.Value}>{formatAbsoluteNumber(values.transformed)}</span>
            </div>
            <div className={SocialImpactStyles.StatsRow} data-testid="social-impact-improved-total">
              <span className={SocialImpactStyles.Dot} style={{ backgroundColor: colours.pink1 }} />
              <span className={SocialImpactStyles.Title}>Improved</span>
              <span className={SocialImpactStyles.Value}>{formatAbsoluteNumber(values.improved)}</span>
            </div>
            <div className={SocialImpactStyles.StatsRow} data-testid="social-impact-connected-total">
              <span className={SocialImpactStyles.Dot} style={{ backgroundColor: colours.purple1 }} />
              <span className={SocialImpactStyles.Title}>Connected</span>
              <span className={SocialImpactStyles.Value}>{formatAbsoluteNumber(values.connected)}</span>
            </div>
          </div>
        ) : (
          <NoDataViewSmall />
        )}
      </div>
      <div className="GraphSideBarCard">
        <div className="CardHeading">
          <div className="CardHeadingColumn">
            <h3>People positively impacted</h3>
            <Tooltip
              className={SocialImpactStyles.Tooltip}
              tooltipText="Share of people positively impacted out of people supported"
            >
              <InformationIndicator className={SocialImpactStyles.TooltipIcon} fill={undefined} />
            </Tooltip>
          </div>
          <div className="CardHeadingColumn">
            <div>
              <span className="Unit">YTD</span>
            </div>
          </div>
        </div>
        {percentageSeriesHasData ? <PercentageDonutChart series={percentageSeries} /> : <NoDataViewSmall />}
      </div>
    </div>
  )
}

export function ChangeMakersGraphSideBarCards({ changeMakers, currFyName }: ChangeMakersGraphSideBarCardsProps) {
  const isLoading = changeMakers.length === 0
  const isEmpty = (!isLoading && changeMakers.every(d => d.coworkers === null && d.customers === null)) ?? false

  if (isLoading) return <SideBarCardsLoading className="GraphRow" />

  const coworkersTotal = sumBy(changeMakers, 'coworkers')
  const customersTotal = sumBy(changeMakers, 'customers')

  const changeMakersTotal = coworkersTotal + customersTotal ?? 1
  const coworkersRelative = coworkersTotal / changeMakersTotal
  const customersRelative = customersTotal / changeMakersTotal
  const percentageSeries = [
    {
      name: 'Customers',
      color: '#7530BD',
      value: customersRelative
    },
    {
      name: 'Co-workers',
      color: '#FFA58C',
      value: coworkersRelative
    }
  ]

  return (
    <div className={classNames('GraphRow', 'three-in-row')}>
      <div className="GraphSideBarCard" data-testid="change-makers-coworkers">
        <div className="CardHeading">
          <h3>Co-workers</h3>
          {changeMakers && (
            <div>
              <span className="Amount">{formatAbsoluteNumber(coworkersTotal)}</span>{' '}
              <span className="Unit">co-workers YTD</span>
            </div>
          )}
        </div>
        <FinancialYearChartContainer
          generator={barChart}
          series={
            changeMakers && [
              {
                name: currFyName,
                color: colours.salmon,
                data: changeMakers.filter(d => d.coworkers !== null).map(d => ({ x: d.date, y: d.coworkers ?? 0 }))
              }
            ]
          }
          domainFromSerie={currFyName}
          noData={isEmpty}
          yAxisTitle="no. of people"
          yAxisLabelTooltipOnly
          isSmallGraph
          testId="change-makers-coworkers-graph"
        />
      </div>
      <div className="GraphSideBarCard" data-testid="change-makers-customers">
        <div className="CardHeading">
          <h3>Customers</h3>
          {changeMakers && (
            <div>
              <span className="Amount">{formatAbsoluteNumber(customersTotal)}</span>{' '}
              <span className="Unit">customers YTD</span>
            </div>
          )}
        </div>
        <FinancialYearChartContainer
          generator={barChart}
          series={
            changeMakers && [
              {
                name: currFyName,
                color: colours.purple1,
                data: changeMakers.filter(d => d.customers !== null).map(d => ({ x: d.date, y: d.customers ?? 0 }))
              }
            ]
          }
          domainFromSerie={currFyName}
          noData={isEmpty}
          yAxisTitle="no. of people"
          yAxisLabelTooltipOnly
          isSmallGraph
          testId="change-makers-customers-graph"
        />
      </div>
      <div className="GraphSideBarCard" data-testid="change-makers-sunburst">
        <div className="CardHeading">
          <h3>Division of co-workers and customers</h3>
          {changeMakers && (
            <div>
              <span className="Unit">YTD</span>
            </div>
          )}
        </div>
        {isLoading ? (
          <LoadingSkeleton className={ChangeMakersStyles.SunburstLoading} />
        ) : isEmpty ? (
          <NoDataViewSmall />
        ) : (
          <PercentageDonutChart series={percentageSeries} testId="change-makers-sunburst-graph" />
        )}
      </div>
    </div>
  )
}

export function CustomerDeliverGraphSideBarCards({
  className,
  graphData,
  locationId,
  goal
}: CustomerDeliverGraphSideBarCardsProps) {
  if (!graphData || Object.keys(graphData).length === 0) {
    return <SideBarCardsLoading className={className} />
  }

  const [prevFyName, currFyName] = Object.keys(graphData).sort()
  const currFy = graphData[currFyName]
  const prevFy = graphData[prevFyName]
  const lastAvailableDate = maxBy(currFy, record => record.footprint !== null && record.readableDate)?.readableDate
  const totalCarbonYtd = sumBy(currFy, 'footprint')

  const ZeroEmissionsChart = () => {
    const hasDataFilter = (r: CustomerDeliveries) => r.zeroEmissionDeliveryShare !== null
    const hasData = currFy.some(hasDataFilter) || prevFy.some(hasDataFilter)

    const currentAmount =
      find(currFy, record => record.readableDate === lastAvailableDate)?.zeroEmissionDeliveryShare ?? 0

    return (
      <div className="GraphSideBarCard">
        <div className="CardHeading">
          <h3>Zero Emission Delivery Share</h3>
          {hasData && (
            <div>
              <span className="Amount">{formatRelativeNumber(currentAmount * 100)}</span>{' '}
              <span className="Unit">%</span>
            </div>
          )}
        </div>
        {hasData && currFy.length > 0 ? (
          <FinancialYearChartContainer
            testId="customer-deliveries-zero-emissions-chart"
            domainFromSerie={prevFyName}
            generator={lineChart}
            lineChartConfiguration={{
              focusStyle: 'none',
              startFromZero: false
            }}
            series={
              !hasData
                ? []
                : ([
                    {
                      name: prevFyName,
                      color: colours.offWhite1,
                      fill: colours.grey1,
                      data: prevFy.map(d => ({
                        x: new Date(d.readableDate),
                        y: d.zeroEmissionDeliveryShare * 100
                      })),
                      unit: '%'
                    },
                    {
                      name: currFyName,
                      color: colours.salmon1,
                      data: currFy
                        .filter(d => d.zeroEmissionDeliveryShare !== null)
                        .map((d, idx) => ({
                          x: new Date(prevFy[idx]?.readableDate),
                          y: d.zeroEmissionDeliveryShare * 100
                        })),
                      unit: '%'
                    },
                    goal
                      ? {
                          name: `${currFyName} Goal`,
                          color: colours.lightBlue2,
                          data: currFy.map((d, idx) => ({
                            x: new Date(prevFy[idx]?.readableDate),
                            y: goal * 100
                          })),
                          unit: '%'
                        }
                      : false
                  ].filter(Boolean) as Serie[])
            }
            isSmallGraph
            disableLegendItems
          />
        ) : (
          <NoDataViewSmall
            text={
              locationId === 'ALL'
                ? 'Unfortunately Zero Emissions Delivery Share is unavailable in the global view. Please select a country to see this data!'
                : undefined
            }
          />
        )}
      </div>
    )
  }

  const EmissionsPerOrderChart = () => {
    const totalDeliveriesYtd = sumBy(currFy, 'deliveries')
    const avgEmissionsPerDelivery = totalCarbonYtd / totalDeliveriesYtd

    const hasDataFilter = (r: CustomerDeliveries) => r.emissionsPerOrder !== null
    const hasData = currFy.some(hasDataFilter) || prevFy.some(hasDataFilter)

    return (
      <div className="GraphSideBarCard">
        <div className="CardHeading">
          <h3>Emissions per order</h3>
          {hasData && (
            <div>
              <span className="Amount">{formatRelativeNumber(avgEmissionsPerDelivery || 0)}</span>
              <span className="Unit"> kg CO2e / order avg.</span>
            </div>
          )}
        </div>
        {prevFy.concat(currFy).length > 0 ? (
          <FinancialYearChartContainer
            testId="customer-deliveries-order-emissions-chart"
            generator={barChart}
            domainFromSerie={prevFyName}
            series={
              !hasData
                ? []
                : [
                    {
                      name: currFyName,
                      color: colours.yellow1,
                      data: currFy.map((d, idx) => ({ x: new Date(prevFy[idx].readableDate), y: d.emissionsPerOrder })),
                      unit: '%',
                      secondaryName: prevFyName,
                      secondaryColor: colours.lightYellow,
                      secondaryData: prevFy.map(d => ({ x: new Date(d.readableDate), y: d.emissionsPerOrder })),
                      secondaryUnit: '%'
                    }
                  ]
            }
            isSmallGraph
            disableLegendItems
          />
        ) : (
          <NoDataViewSmall />
        )}
      </div>
    )
  }

  const EmissionsPerKilometre = () => {
    const kilometresYtd = sumBy(currFy, 'kilometresTravelled')
    const avgEmissionsPerKilometre = (1000 * totalCarbonYtd) / kilometresYtd

    const hasDataFilter = (r: CustomerDeliveries) => r.emissionsPerKilometre !== null
    const hasData = currFy.some(hasDataFilter) || prevFy.some(hasDataFilter)

    return (
      <div className="GraphSideBarCard">
        <div className="CardHeading">
          <h3>Emissions per km</h3>
          {hasData && (
            <div>
              <span className="Amount">{formatRelativeNumber(Number(avgEmissionsPerKilometre) || 0)}</span>
              <span className="Unit"> g CO2e / km avg.</span>
            </div>
          )}
        </div>
        {prevFy.concat(currFy).length > 0 ? (
          <FinancialYearChartContainer
            testId="customer-deliveries-km-emissions-chart"
            generator={barChart}
            domainFromSerie={prevFyName}
            series={
              !hasData
                ? []
                : [
                    {
                      name: currFyName,
                      color: colours.salmon1,
                      data: currFy.map((d, idx) => ({
                        x: new Date(prevFy[idx].readableDate),
                        y: d.emissionsPerKilometre * 1000
                      })),
                      unit: '%',
                      secondaryName: prevFyName,
                      secondaryColor: colours.lightPink1,
                      secondaryData: prevFy.map(d => ({
                        x: new Date(d.readableDate),
                        y: d.emissionsPerKilometre * 1000
                      })),
                      secondaryUnit: '%'
                    }
                  ]
            }
            isSmallGraph
            disableLegendItems
          />
        ) : (
          <NoDataViewSmall />
        )}
      </div>
    )
  }

  return (
    <div className={className}>
      <ZeroEmissionsChart />
      <EmissionsPerOrderChart />
      <EmissionsPerKilometre />
    </div>
  )
}

export function WaterGraphSideBarCards({ className, graphData, isLoading, isSite }: WaterGraphSideBarCardsProps) {
  if (isLoading) {
    return <SideBarCardsLoading className={className} />
  }

  const NoDataViewSmallWhithConteiner = () => {
    return (
      <div className="GraphSideBarCard">
        <NoDataViewSmall />
      </div>
    )
  }

  const ShareWaterChart = () => {
    const data = getGraphData(graphData)
    if (!data) {
      return <NoDataViewSmallWhithConteiner />
    }

    const { prevFyName, currFyName, currFy, prevFy } = data

    const hasDataFilter = (r: WaterSmallCardData) => r.shareWaterHighStressAreas !== null
    const hasData =
      currFy?.some(hasDataFilter) ||
      prevFy?.some(hasDataFilter) ||
      currFy?.some(r => r.shareWaterHighStressAreas !== null)
    const shareWaterHighStressAreasYTD = _.last(currFy)?.shareWaterHighStressAreasYTD

    const description = `
      Water stress indicates the shortage of water in some areas. The view will be in 2 conditions:
      For the share on global and country levels the graph will show the monthly values.
      For site - an indication of the water stress level in coloured box. The color of the box depends from the water stress level (low, low-medium, medium-high, high, extremely-high)
    `

    return (
      <div className="GraphSideBarCard">
        {isSite ? (
          <div className="water-site-level">
            <div className="CardHeading water-site-level">
              <h3>Water stress level</h3>
              <Tooltip tooltipText={description}>
                <InformationIndicator className="Icon" fill="#767676" />
              </Tooltip>
            </div>
            {currFy && currFy.length ? (
              <div className="water-site-content">
                <div className={`stress-level ${_.last(currFy)?.waterStress?.toLowerCase()}`}>
                  {_.capitalize(_.last(currFy)?.waterStress)}
                </div>
              </div>
            ) : (
              <NoDataViewSmall />
            )}
          </div>
        ) : (
          <>
            <div className="CardHeading">
              <h3>Share of water in high stress areas</h3>
              {hasData && (
                <div>
                  <span className="Amount">{formatRelativeNumber(Number(shareWaterHighStressAreasYTD) || 0)}</span>
                  <span className="Unit"> % YTD</span>
                </div>
              )}
            </div>
            {hasData && currFy.length > 0 ? (
              <FinancialYearChartContainer
                testId="share-water-chart"
                domainFromSerie={prevFyName}
                generator={lineChart}
                lineChartConfiguration={{
                  focusStyle: 'none',
                  startFromZero: false
                }}
                series={[
                  {
                    name: prevFyName,
                    color: colours.offWhite1,
                    fill: colours.grey1,
                    data: prevFy.map(d => ({
                      x: new Date(d.readableDate),
                      y: d.shareWaterHighStressAreas
                    })),
                    unit: '%'
                  },
                  {
                    name: currFyName,
                    color: colours.blue10,
                    data: currFy
                      .filter(d => d.shareWaterHighStressAreas !== null)
                      .map((d, idx) => ({
                        x: new Date(prevFy[idx]?.readableDate),
                        y: d.shareWaterHighStressAreas
                      })),
                    unit: '%'
                  }
                ]}
                isSmallGraph
                disableLegendItems
              />
            ) : (
              <NoDataViewSmall />
            )}
          </>
        )}
      </div>
    )
  }

  const WaterConsumptionChart = () => {
    const data = getGraphData(graphData)
    if (!data) {
      return <NoDataViewSmallWhithConteiner />
    }

    const { prevFyName, currFyName, currFy, prevFy } = data

    const totalWaterYTD = sumBy(currFy, 'totalWater')

    const hasDataFilter = (r: WaterSmallCardData) => r.totalWater !== null
    const hasData = currFy?.some(hasDataFilter) || prevFy?.some(hasDataFilter)

    return (
      <div className="GraphSideBarCard">
        <div className="CardHeading">
          <h3>Water consumption</h3>
          {hasData && (
            <div>
              <span className="Amount">{formatAbsoluteNumber(totalWaterYTD || 0)}</span>
              <span className="Unit"> litres YTD</span>
            </div>
          )}
        </div>
        {prevFy.concat(currFy).length > 0 ? (
          <FinancialYearChartContainer
            testId="water-consumption-chart"
            generator={barChart}
            domainFromSerie={prevFyName}
            series={
              !hasData
                ? []
                : [
                    {
                      name: currFyName,
                      color: colours.lightBlue12,
                      data: currFy.map(d => {
                        const splitted = d.readableDate.split('-')
                        const readableDate = endOfMonth(new Date(`${Number(splitted[0]) - 1}-${splitted[1]}-1`))
                        return { x: readableDate, y: d.totalWater }
                      }),
                      secondaryName: prevFyName,
                      secondaryColor: colours.lightBlue13,
                      secondaryData: prevFy.map(d => {
                        const readableDate = endOfMonth(new Date(d.readableDate))
                        return { x: readableDate, y: d.totalWater }
                      })
                    }
                  ]
            }
            isSmallGraph
            disableLegendItems
          />
        ) : (
          <NoDataViewSmall />
        )}
      </div>
    )
  }

  const VisitorsChart = () => {
    const data = getGraphData(graphData)
    if (!data) {
      return <NoDataViewSmallWhithConteiner />
    }

    const { prevFyName, currFyName, currFy, prevFy } = data

    const totalVisitorsYtd = sumBy(currFy, 'totalVisitors')

    const hasDataFilter = (r: WaterSmallCardData) => r.totalVisitors !== null
    const hasData = currFy?.some(hasDataFilter) || prevFy?.some(hasDataFilter)

    return (
      <div className="GraphSideBarCard">
        <div className="CardHeading">
          <h3>Visitors</h3>
          {hasData && (
            <div>
              <span className="Amount">{formatAbsoluteNumber(Number(totalVisitorsYtd) || 0)}</span>
              <span className="Unit"> visitors YTD</span>
            </div>
          )}
        </div>
        {prevFy.concat(currFy).length > 0 ? (
          <FinancialYearChartContainer
            testId="visitors-chart"
            generator={barChart}
            domainFromSerie={prevFyName}
            series={
              !hasData
                ? []
                : [
                    {
                      name: currFyName,
                      color: colours.blue10,
                      data: currFy.map(d => {
                        const splitted = d.readableDate.split('-')
                        const readableDate = endOfMonth(new Date(`${Number(splitted[0]) - 1}-${splitted[1]}-1`))
                        return { x: readableDate, y: d.totalVisitors }
                      }),
                      secondaryName: prevFyName,
                      secondaryColor: colours.lightBlue14,
                      secondaryData: prevFy.map(d => {
                        const readableDate = endOfMonth(new Date(d.readableDate))
                        return { x: readableDate, y: d.totalVisitors }
                      })
                    }
                  ]
            }
            isSmallGraph
            disableLegendItems
          />
        ) : (
          <NoDataViewSmall />
        )}
      </div>
    )
  }

  return (
    <div className={className}>
      <WaterConsumptionChart />
      <VisitorsChart />
      <ShareWaterChart />
    </div>
  )
}

const getGraphData = (graphData: Dictionary<WaterSmallCardData[]> | undefined) => {
  if (!graphData || Object.keys(graphData).length === 0) {
    return null
  }

  const [prevFyName, currFyName] = Object.keys(graphData).sort()
  const currFy = graphData[currFyName]
  const prevFy = graphData[prevFyName]

  if (!currFy || !prevFy) {
    return null
  }

  return { prevFyName, currFyName, currFy, prevFy }
}
