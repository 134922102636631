import React from 'react'
import { GridHr, Title } from '../../../components/Benchmark/GridHelpers'
import { TimeRangeSelector } from '../../../components/Benchmark/TimeRangeSelector'
import { BenchmarkingContext } from './Provider'
import { LoadingSkeleton } from '../../../components/LoadingSkeleton'
import { BenchmarkTableRow, TableRowKey } from '../../../components/Benchmark/BenchmarkTableRow'
import { useSocialData } from './hooks/useSocialData'
import { formatAbsoluteNumber } from '../../../components/Utils/format'
import { useBenchmarkHelperDataSI } from './hooks/helpers'

export const SocialImpactTable = () => {
  const context = React.useContext(BenchmarkingContext)
  const data = useSocialData()
  const { prevOptions, currOptions, currFyLabel, nextFyLabel } = useBenchmarkHelperDataSI()

  if (!context) return null

  const { prevTimeRange, currTimeRange, expandedKey } = context.data

  const setPrevTimeRange = (value: 'YTD' | 'Result') => {
    context.dispatch({ type: 'prevTimeRange', payload: value })
  }

  const setCurrTimeRange = (value: 'YTD' | 'Rolling 12 mos') => {
    context.dispatch({ type: 'currTimeRange', payload: value })
  }

  const commonRowProps = {
    expandedKey,
    toggleExpanded: context.toggleExpanded
  }

  return (
    <>
      <div className="TableHeadTitle Social">Fair & EQUAL</div>
      <div className="Table">
        <Title className="KPI">KPI / PI</Title>
        <Title className="SiteCountry">Site/country</Title>
        <TimeRangeSelector
          className="ResultPrevFy Title"
          options={prevOptions}
          currentValue={prevTimeRange === 'YTD' ? prevOptions[0] : prevOptions[1]}
          onValueChanged={value => (value === prevOptions[0] ? setPrevTimeRange('YTD') : setPrevTimeRange('Result'))}
        />
        <TimeRangeSelector
          className="Comparison Title"
          options={currOptions}
          currentValue={currTimeRange === 'YTD' ? currOptions[0] : currOptions[1]}
          onValueChanged={value =>
            value === currOptions[0] ? setCurrTimeRange('YTD') : setCurrTimeRange('Rolling 12 mos')
          }
        />
        <Title className="Goal">{currFyLabel} Goal</Title>
        <Title className="GoalNext">{nextFyLabel} Goal</Title>
        <GridHr isHead />
        {!data?.socialImpactData ? (
          <LoadingSkeleton />
        ) : (
          <BenchmarkTableRow
            kpiUnits="no. of people positively impacted"
            locationResults={data.socialImpactData.locationResult}
            childLocationResults={data.socialImpactData.childLocationResults}
            rowKey={TableRowKey.SocialImpact}
            formatNumber={formatAbsoluteNumber}
            {...commonRowProps}
          />
        )}
        <GridHr />
        {!data?.changeMakersData ? (
          <LoadingSkeleton />
        ) : (
          <BenchmarkTableRow
            kpiUnits="no. of change-makers/no. of visitors of the country"
            locationResults={data.changeMakersData.locationResult}
            childLocationResults={data.changeMakersData.childLocationResults}
            rowKey={TableRowKey.ChangeMakers}
            formatNumber={formatAbsoluteNumber}
            {...commonRowProps}
          />
        )}
      </div>
    </>
  )
}
