import Button from '@ingka/button'
import classNames from 'classnames'
import { format, isSameMonth, max, sub } from 'date-fns'
import { drop, flatMap, groupBy, uniq } from 'lodash'
import React, { Fragment } from 'react'

import {
  ApiTotalFootprint,
  Insight,
  KPIBenchmark,
  RecoveredProducts,
  GoalsResponse
} from '../../../../api/src/common-types'
// import { InPageInsights } from '../../../components/InPageInsights'
import {
  Benchmarking,
  CardRow,
  KPIPerformance,
  MainCard,
  Stripe,
  RenderBenchmarkType,
  BenchmarkingModal,
  DataSourceAndModalButton,
  sortBenchmarks
} from '../../../components/KPIPage'
import { Link } from '../../../components/Link'
import { KpiModalState, KpiPageLearnMoreModal } from '../../../components/Modal'
import { PageHeader } from '../../../components/PageHeader'
import { TopBar } from '../../../components/TopBar'
import { formatAbsoluteNumber, formatRelativeNumber } from '../../../components/Utils/format'
import {
  getCluster,
  getCountry,
  getGoalsForLocation,
  getLocationId,
  getLocationLabel,
  getLocationSelector,
  isCountryCode,
  isCluster
} from '../../../components/Utils/utils'
import { zeroWasteTypes, ZeroWasteSmallGraphs, WasteRecyclingRateGraph } from '../../../components/ZeroWasteGraph'
import {
  getGoals,
  getInsights,
  getRecoveredProducts,
  getTotalFootprint,
  isCountryOrGlobal
} from '../../../lib/APIClient'
import { useLocations, useDataAvailabilityContext } from '../../../context'
import { useSharedSelections } from '../../../SharedSelections'
import { useDocumentTitle } from '../../../components/Utils/use-document-title'
import { Route } from '../../../routes'

import '../KPIPage.scss'
import colours from '../../../Colours.module.scss'
import { getIngkaFinancialYear } from '../../../components/Utils/dates'
import { LoadingSkeleton } from '../../../components/LoadingSkeleton'

interface RecyclingRateBenchmark extends KPIBenchmark {
  currentYtdRecyclingRate?: number
  previousYtdRecyclingRate?: number
  previousFyRecyclingRate?: number
  goal?: number
  goalNextFY?: number
}

export interface ZeroWasteBenchmark extends RecyclingRateBenchmark {
  currentYtd: number
  previousYtd: number
  previousFyResult: number
  rolling12: number
}

export interface WasteData {
  readableDate: string
  raw: number
  disposalType: string
  fiscalYear: number
}

export function getZeroWasteBenchmarks(
  wasteData: ApiTotalFootprint[],
  goals: GoalsResponse | undefined,
  currFy: number,
  locationId: string
) {
  const lastDateForPrevYtd = !wasteData.length
    ? ''
    : format(sub(max(wasteData.map(w => new Date(w.readableDate))), { years: 1 }), 'yyyy-MM-dd')
  const currYtdData = wasteData.filter(w => w.fiscalYear === currFy)
  const currYtdDataForLocation = currYtdData.filter(w => locationId.length <= 3 || w.siteId === locationId)

  const currYtd = calculateTotalNonZeroWaste(currYtdDataForLocation)
  const currYtdTotal = currYtdDataForLocation.reduce((acc, w) => acc + w.quantity, 0) / 1000

  const prevYtdData = wasteData.filter(w => w.fiscalYear === currFy - 1 && w.readableDate <= lastDateForPrevYtd)

  const readableDates = uniq(wasteData.map(({ readableDate }) => readableDate)).sort()
  const rolling12ReadableDates = drop(readableDates, readableDates.length - 12)
  const rolling12Data = wasteData.filter(w => rolling12ReadableDates.includes(w.readableDate))

  const isAll = locationId === 'ALL'
  const groupingKey = isAll ? 'countryCode' : 'siteId'
  const locationIds = uniq(wasteData.map(w => w[groupingKey]))
  const benchmarks: ZeroWasteBenchmark[] = locationIds.map(key => {
    const ytdFilterCb = (w: ApiTotalFootprint) => w[groupingKey] === key
    const previousFyFilterCb = (w: ApiTotalFootprint) => w[groupingKey] === key && w.fiscalYear === currFy - 1

    const currentYtd = calculateTotalNonZeroWaste(currYtdData.filter(ytdFilterCb))
    const previousYtd = calculateTotalNonZeroWaste(prevYtdData.filter(ytdFilterCb))
    const rolling12 = calculateTotalNonZeroWaste(rolling12Data.filter(ytdFilterCb))
    const previousFyResult = calculateTotalNonZeroWaste(wasteData.filter(previousFyFilterCb))

    const currentYtdRecyclingRate = calculateBenchmarkingRecyclingRate(currYtdData, ytdFilterCb)
    const previousYtdRecyclingRate = calculateBenchmarkingRecyclingRate(prevYtdData, ytdFilterCb)
    const previousFyRecyclingRate = calculateBenchmarkingRecyclingRate(wasteData, previousFyFilterCb)

    return {
      id: key,
      label:
        (locationId !== 'ALL' ? wasteData.find(ytdFilterCb)?.siteName : wasteData.find(ytdFilterCb)?.countryName) ?? '',
      selectable: wasteData.find(ytdFilterCb)?.selectable ?? false,
      currentYtd,
      previousYtd,
      previousFyResult,
      rolling12,
      currentYtdRecyclingRate,
      previousYtdRecyclingRate,
      previousFyRecyclingRate,
      goal: getGoalsForLocation(key, goals)?.recyclingRateGoal ?? 0,
      goalNextFY: getGoalsForLocation(key, goals)?.recyclingRateGoalNextFY ?? 0
    }
  })

  const getCurrentLocationBenchmark = () => {
    const ytdFilterCb = (w: ApiTotalFootprint) => isAll || w.countryCode === locationId
    const previousFyFilterCb = (w: ApiTotalFootprint) =>
      isAll || (w.countryCode === locationId && w.fiscalYear === currFy - 1)
    const currentYtdRecyclingRate = calculateBenchmarkingRecyclingRate(currYtdData, ytdFilterCb)
    const previousYtdRecyclingRate = calculateBenchmarkingRecyclingRate(prevYtdData, ytdFilterCb)
    const previousFyRecyclingRate = calculateBenchmarkingRecyclingRate(wasteData, previousFyFilterCb)
    return {
      currentYtdRecyclingRate,
      previousYtdRecyclingRate,
      previousFyRecyclingRate
    }
  }

  const currLocationBenchmark = isCountryCode(locationId)
    ? getCurrentLocationBenchmark()
    : benchmarks.find(b => b.id === locationId)

  return [benchmarks, currYtd, currYtdTotal, currLocationBenchmark] as const
}

export const ZeroWasteKPIPage = () => {
  useDocumentTitle('Zero Waste')

  const page = Route.ZeroWasteKPIPage
  const [{ func }] = useSharedSelections()
  const { dataAvailability } = useDataAvailabilityContext()
  const { currentLocation, clusters, locations } = useLocations()
  const [domain, setDomain] = React.useState<Date[]>([])
  const [wasteData, setWasteData] = React.useState<ApiTotalFootprint[] | undefined>(undefined)
  const [benchmarkModalOpen, setBenchmarkModalOpen] = React.useState(false)
  const [, setInsights] = React.useState<Insight[] | null>(null)
  const [goals, setGoals] = React.useState<GoalsResponse>()
  const [recoveredProducts, setRecoveredProducts] = React.useState<RecoveredProducts[] | undefined>(undefined)
  const [modalState, setModalState] = React.useState<KpiModalState>({ isOpen: false })
  const [lastUpdated, setLastUpdated] = React.useState('')
  const locationId = getLocationId(currentLocation)

  const goalsFy = dataAvailability?.planetCurrentFY ?? 2024

  const allDates = React.useMemo(() => {
    return Object.values(groupBy(domain, d => getIngkaFinancialYear(d).getFullYear()))[0]?.map(d => {
      const month = d.getMonth()
      const year = d.getFullYear()
      return new Date(year + 1, month + 1, 0)
    })
  }, [domain])

  React.useEffect(() => {
    if (locations.length === 0) {
      return
    }
    setWasteData(undefined)
    setInsights(null)
    setRecoveredProducts(undefined)

    const baseSelector = {
      start_fy: dataAvailability?.planetPreviousFY,
      end_fy: dataAvailability?.planetCurrentFY,
      func: func,
      isOld: true
    }
    const countryOrLocationId = currentLocation.isCluster
      ? currentLocation.cluster.clusterId
      : currentLocation.location.countryCode

    getTotalFootprint(
      {
        ...getLocationSelector(countryOrLocationId, getCluster(clusters, countryOrLocationId)?.countryCodes),
        ...baseSelector
      },
      ['waste']
    ).then(response => {
      setDomain(response.dates.map(d => new Date(d)))
      setWasteData(response.data)
      response.lastUpdated && setLastUpdated(format(new Date(response.lastUpdated), 'dd/MM/yyyy'))
    })

    if (!currentLocation.isCluster) {
      getInsights({
        locationId,
        func
      }).then(result => setInsights(result.filter(({ type }) => type === 'zero-waste')))
    } else {
      setInsights([])
    }

    getRecoveredProducts({
      ...getLocationSelector(locationId, getCluster(clusters, locationId)?.countryCodes),
      ...baseSelector
    }).then(setRecoveredProducts)

    if (!isCluster(locationId)) {
      getGoals(getCountry(locationId, locations).countryCode, func, goalsFy).then(setGoals)
    }
  }, [getLocationId(currentLocation), JSON.stringify(func), locations.length])

  const wasteDataForLocation = React.useMemo(() => {
    return wasteData
      ? wasteData
          .filter(
            w => currentLocation.isCluster || isCountryOrGlobal(currentLocation.location) || w.siteId === locationId
          )
          .map(w => ({ ...w, quantity: w.quantity / 1000 }))
      : undefined
  }, [JSON.stringify(wasteData)])

  const { nonZeroYtdSum, currYtd, currYtdTotal, benchmarks, recyclingSeries, nonRecycledWaste, currLocationBenchmark } =
    React.useMemo(() => {
      if (!wasteData || !wasteDataForLocation) {
        return {
          nonZeroYtdSum: undefined,
          currYtd: undefined,
          currYtdTotal: undefined,
          benchmarks: [] as RecyclingRateBenchmark[],
          recyclingSeries: undefined
        }
      }

      const [benchmarks, currYtd, currYtdTotal, currLocationBenchmark] = getZeroWasteBenchmarks(
        wasteData,
        goals,
        (dataAvailability?.planetCurrentFY ?? 2000) - 2000,
        locationId
      )

      const recycling = flatMap(domain, d => {
        const dataForDate = wasteDataForLocation.filter(w => isSameMonth(new Date(w.readableDate), d))
        if (dataForDate.length === 0) {
          return []
        }
        const fiscalYear = dataForDate[0].fiscalYear
        const nonZero = calculateTotalNonZeroWaste(dataForDate)
        const total = dataForDate.reduce((acc, w) => acc + w.quantity, 0) / 1000
        return { date: d, fiscalYear, recyclingShare: ((total - nonZero) / total) * 100 }
      })
      const recyclingSeries =
        recycling.length === 0
          ? []
          : [
              (dataAvailability?.planetPreviousFY ?? 2000) - 2000,
              (dataAvailability?.planetCurrentFY ?? 2000) - 2000
            ].map((fy, i) => ({
              name: `FY${fy}`,
              data: recycling
                .filter(r => r.fiscalYear === fy)
                .map(r => {
                  const date = domain.find(item => item.getMonth() === r.date.getMonth())
                  return { x: date || new Date(), y: r.recyclingShare }
                }),
              color: i === 0 ? colours.offWhite1 : colours.purple1,
              fill: i === 0 ? colours.grey1 : undefined,
              zIndex: i,
              unit: '%'
            }))

      return {
        nonZeroYtdSum: (
          <span>
            {formatAbsoluteNumber(currYtd)}
            <span className="Unit"> tonnes YTD</span>
          </span>
        ),
        nonRecycledWaste: (
          <div>
            <span className="Amount">{formatRelativeNumber(currLocationBenchmark?.currentYtdRecyclingRate ?? 0)}</span>
            <span className="Label"> % YTD</span>
          </div>
        ),
        currYtd,
        currYtdTotal,
        benchmarks,
        currLocationBenchmark,
        recyclingSeries
      }
    }, [JSON.stringify(wasteData), JSON.stringify(wasteDataForLocation), JSON.stringify(goals), locationId])

  const { recoveredSeries, recoveredProductsYtdShare } = React.useMemo(() => {
    if (!recoveredProducts) {
      return { recoveredSeries: undefined, recoveredProductsYtdShare: undefined }
    }
    if (recoveredProducts.length === 0) {
      return { recoveredSeries: [], recoveredProductsYtdShare: undefined }
    }
    const ytdShare = recoveredProducts
      .filter(r => r.fiscalYear === (dataAvailability?.planetCurrentFY ?? 2000) - 2000)
      .reduce(
        (acc, r) => ({
          recovered: acc.recovered + r.productsRecovered,
          potential: acc.potential + r.potentialRecoverable
        }),
        { recovered: 0, potential: 0 }
      )

    const recoveredSeries = [
      (dataAvailability?.planetPreviousFY ?? 2000) - 2000,
      (dataAvailability?.planetCurrentFY ?? 2000) - 2000
    ].map((fy, i) => ({
      name: `FY${fy}`,
      data: recoveredProducts
        ? recoveredProducts
            .filter(r => r.fiscalYear === fy)
            .map(r => {
              const date = (allDates || []).find(item => item.getMonth() === new Date(r.readableDate).getMonth())
              return {
                x: date || new Date(),
                y: (r.productsRecovered / r.potentialRecoverable) * 100
              }
            })
        : [],
      color: i === 0 ? colours.offWhite1 : colours.purple1,
      fill: i === 0 ? colours.grey1 : undefined,
      zIndex: i,
      unit: '%'
    }))
    return {
      recoveredSeries,
      recoveredProductsYtdShare:
        ytdShare && ytdShare.potential > 0 ? ytdShare.recovered / ytdShare.potential : undefined
    }
  }, [JSON.stringify(recoveredProducts), allDates])

  const renderBenchmark: RenderBenchmarkType<RecyclingRateBenchmark> = (benchmark, keys, classes) => (
    <Fragment key={benchmark.id}>
      <div className={classNames('FirstItem', classes)}>{benchmark.label}</div>
      <div className={classNames(classes)}>{formatRelativeNumber(benchmark[keys[0]] as number)}</div>
      <div
        className={classNames('Right', classes, 'YTD', {
          OnTrack: (benchmark.currentYtdRecyclingRate ?? 0) >= (benchmark.goal ?? 0)
        })}
      >
        {formatRelativeNumber(benchmark.currentYtdRecyclingRate ?? 0)}
      </div>

      <div className={classNames(classes)}>{benchmark.goal ? formatRelativeNumber(benchmark.goal) : 'N/A'}</div>
      <div className={classNames(classes)}>
        {benchmark.goalNextFY ? formatRelativeNumber(benchmark.goalNextFY) : 'N/A'}
      </div>
      <div />
    </Fragment>
  )

  const exploreButton = (
    <Link page={Route.ZeroWasteExplorePage}>
      <Button text="Open Explore" type="primary" small />
    </Link>
  )

  const footerBenchmark = {
    id: locationId,
    label: getLocationLabel(currentLocation),
    selectable: true,
    previousYtdRecyclingRate: currLocationBenchmark?.previousYtdRecyclingRate ?? 0,
    currentYtdRecyclingRate: currLocationBenchmark?.currentYtdRecyclingRate ?? 0,
    previousFyRecyclingRate: currLocationBenchmark?.previousFyRecyclingRate ?? 0,
    goal: getGoalsForLocation(locationId, goals)?.recyclingRateGoal ?? 0,
    goalNextFY: getGoalsForLocation(locationId, goals)?.recyclingRateGoalNextFY ?? 0
  }
  const benchmarksWithoutNonSelectableSites = benchmarks.filter(x => x.selectable)

  const currFyHeading = `FY${(dataAvailability?.planetCurrentFY ?? 2000) - 2000} YTD`
  const prevFyHeading = `FY${(dataAvailability?.planetPreviousFY ?? 2000) - 2000} YTD`
  const prevFyResultHeading = `FY${(dataAvailability?.planetPreviousFY ?? 2000) - 2000} Result`
  const recyclingYtdShare = currYtd && currYtdTotal && currYtdTotal > 0 ? currYtd / currYtdTotal : undefined
  const goalHeading = `FY${goalsFy - 2000} Goal`
  const goalHeadingNext = `FY${goalsFy + 1 - 2000} Goal`
  const currLocationRecyclingRateGoal = getGoalsForLocation(locationId, goals)
  const recyclingSeriesWithGoals =
    recyclingSeries && recyclingSeries.length
      ? [
          ...recyclingSeries,
          {
            color: '#A5B4D9',
            data: Object.values(groupBy(domain, d => getIngkaFinancialYear(d).getFullYear()))[0]?.map(date => ({
              x: date,
              y: currLocationRecyclingRateGoal ? currLocationRecyclingRateGoal?.recyclingRateGoal : 0
            })),
            fill: undefined,
            name: `FY${(dataAvailability?.planetCurrentFY ?? 2000) - 2000} Goal`,
            zIndex: 0,
            unit: '%'
          }
        ]
      : recyclingSeries

  return (
    <div className="KPIPage">
      <TopBar currentPage={page} useInFlexLayout exploreButton={exploreButton} />
      <PageHeader className="ZeroWasteHeader" route={Route.ZeroWasteKPIPage}></PageHeader>
      <div className="PageContent">
        <Stripe title="Zero Waste">
          <DataSourceAndModalButton
            dataSource="Sustain"
            lastUpdated={lastUpdated}
            onClick={() => setModalState({ isOpen: true, page })}
          />
        </Stripe>
        <CardRow className="BenchmarkingAndGoals">
          <Benchmarking
            key="benchmarking"
            benchmarks={
              !wasteData
                ? []
                : [
                    ...(isCountryCode(locationId) ? [footerBenchmark] : []),
                    ...sortBenchmarks(benchmarksWithoutNonSelectableSites, 'currentYtdRecyclingRate', locationId)
                  ].slice(0, 4)
            }
            label=" % of waste being recycled"
            headers={[
              [
                { name: prevFyHeading, key: 'previousYtdRecyclingRate' },
                { name: prevFyResultHeading, key: 'previousFyRecyclingRate' }
              ],
              [{ name: currFyHeading, key: 'currentYtdRecyclingRate' }],
              [{ name: goalHeading, key: 'goal' }],
              [{ name: goalHeadingNext, key: 'goalNextFY' }]
            ]}
            locationId={locationId}
            renderBenchmark={renderBenchmark}
            openModal={() => setBenchmarkModalOpen(true)}
            totalLocations={benchmarksWithoutNonSelectableSites.length}
          />
          <KPIPerformance
            heading="KPI Performance"
            key="goals"
            units={['YTD']}
            kpis={
              !wasteData
                ? []
                : [
                    {
                      key: 'Share of Recovered Products',
                      unit: '%',
                      value: recoveredProductsYtdShare ? formatAbsoluteNumber(recoveredProductsYtdShare * 100) : ''
                    },
                    {
                      key: 'Non-recycled waste',
                      unit: 'tonnes',
                      value: currYtd ? formatAbsoluteNumber(currYtd) : ''
                    },
                    {
                      key: 'Total waste',
                      unit: 'tonnes',
                      value: currYtdTotal ? formatAbsoluteNumber(currYtdTotal) : ''
                    },
                    {
                      key: 'Recycling rate',
                      unit: '%',
                      value: currLocationBenchmark?.currentYtdRecyclingRate
                        ? formatRelativeNumber(currLocationBenchmark.currentYtdRecyclingRate)
                        : '',
                      keyClassNames: ['Bold'],
                      valueClassNames: ['Bold'],
                      colorClass:
                        (currLocationRecyclingRateGoal?.recyclingRateGoal ?? 0) <=
                        (currLocationBenchmark?.currentYtdRecyclingRate ?? 0)
                          ? 'OnTrack'
                          : 'NotOnTrack'
                    },
                    {
                      key: `FY${(dataAvailability?.planetCurrentFY || 2025) - 2000} Goal`,
                      unit: '%',
                      value: currLocationRecyclingRateGoal?.recyclingRateGoal
                        ? formatRelativeNumber(currLocationRecyclingRateGoal.recyclingRateGoal)
                        : '',
                      keyClassNames: ['Bold'],
                      valueClassNames: ['Bold']
                    }
                  ]
            }
          />
        </CardRow>
        <MainCard title="Recycling rate" subtitle={nonRecycledWaste}>
          <div className="GraphContainer">
            {domain ? (
              <WasteRecyclingRateGraph dates={domain} data={recyclingSeriesWithGoals} dateFormat="month" />
            ) : (
              <LoadingSkeleton />
            )}
          </div>
        </MainCard>
        <ZeroWasteSmallGraphs
          className={classNames('GraphRow', 'three-in-row')}
          totalWaste={wasteDataForLocation?.map(w => ({
            readableDate: w.readableDate,
            raw: w.quantity,
            fiscalYear: w.fiscalYear,
            disposalType: w.treatmentTypeId
          }))}
          allDates={allDates}
          recyclingSeries={recyclingSeries}
          recoveredSeries={recoveredSeries}
          recoveredProductsYtdShare={recoveredProductsYtdShare}
          recyclingYtdShare={recyclingYtdShare}
          dateFormat="month"
          dates={domain}
          subtitle={nonZeroYtdSum}
        />
        {/* {insights && insights.length > 0 && <InPageInsights insights={insights} />} */}
        <Stripe
          title="Want to learn more about Zero Waste?"
          subtitle="Explore contains additional charts and time selections."
        >
          {exploreButton}
        </Stripe>
      </div>
      <KpiPageLearnMoreModal
        lastUpdated={lastUpdated}
        modalState={modalState}
        onClose={() => setModalState({ isOpen: false })}
      />
      {benchmarks.length > 0 && (
        <BenchmarkingModal
          benchmarks={benchmarksWithoutNonSelectableSites.filter(x => x.id !== locationId)}
          closeFn={() => setBenchmarkModalOpen(false)}
          footerBenchmark={footerBenchmark}
          headers={[
            [
              { name: prevFyHeading, key: 'previousYtdRecyclingRate' },
              { name: prevFyResultHeading, key: 'previousFyRecyclingRate' }
            ],
            [{ name: currFyHeading, key: 'currentYtdRecyclingRate' }],
            [{ name: goalHeading, key: 'goal' }],
            [{ name: goalHeadingNext, key: 'goalNextFY' }]
          ]}
          isOpen={benchmarkModalOpen}
          locationId={locationId}
          renderBenchmark={renderBenchmark}
          sortBy="currentYtdRecyclingRate"
          sortDirection="asc"
          title="% of waste being recycled"
        />
      )}
    </div>
  )
}

export const calculateTotalNonZeroWaste = (waste: ApiTotalFootprint[]) => {
  return waste.filter(w => !zeroWasteTypes.includes(w.treatmentTypeId)).reduce((acc, w) => acc + w.quantity, 0) / 1000
}

export const calculateBenchmarkingRecyclingRate = (
  ytdData: ApiTotalFootprint[],
  filterCb?: (w: ApiTotalFootprint) => boolean
) => {
  const ytdDataForLocation = filterCb ? ytdData.filter(filterCb) : ytdData
  const totalytd = ytdDataForLocation.reduce((acc, w) => acc + w.quantity, 0) / 1000
  const nonZeroytd = calculateTotalNonZeroWaste(ytdDataForLocation)
  return ((totalytd - nonZeroytd) / totalytd) * 100 || totalytd
}
