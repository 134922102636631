import React from 'react'
import { Route as ReactRoute } from 'react-router-dom'

import { Route } from './Constants'
import { locationId, NavigateTo, WithAuthentication, RetailLink } from './Utils'

// KPI Pages
import { ChangeMakersKPIPage } from '../pages/KPIPages/ChangeMakers/ChangeMakersKPIPage'
import { ClimateKPIPage } from '../pages/KPIPages/Climate/ClimateKPIPage'
import { CustomerTravel } from '../pages/KPIPages/CustomerTravel/CustomerTravel'
import { CustomerDeliveriesKPIPage } from '../pages/KPIPages/CustomerDeliveries/CustomerDeliveriesKPIPage'
import { FoodIngredientsKPIPage } from '../pages/KPIPages/FoodIngredients/FoodIngredientsKPIPage'
import { PppSalesKPIPage } from '../pages/KPIPages/PppSales/PppSalesKPIPage'
import { SocialImpactKPIPage } from '../pages/KPIPages/SocialImpact/SocialImpactKPIPage'
import { ZeroWasteKPIPage } from '../pages/KPIPages/ZeroWaste/ZeroWasteKPIPage'
import { EnergyEfficiencyKPIPage } from '../pages/KPIPages/EnergyEfficiency/EnergyEfficiencyKPIPage'
import { WaterEfficiencyKPIPage } from '../pages/KPIPages/WaterEfficiency/WaterEfficiencyKPIPage'

// Explore Pages
import { ClimateExplorePage } from '../pages/ExplorePages/Climate/ClimateExplorePage'
import { PppSalesExplorePage } from '../pages/ExplorePages/PppSales/PppSalesExplorePage'
import { ZeroWasteExplorePage } from '../pages/ExplorePages/ZeroWaste/ZeroWasteExplorePage'

// InStore Pages
import { InStoreConfiguratorPage } from '../pages/InStore/Configurator/ConfiguratorPage'
import { InStorePage } from '../pages/InStore/InStorePage'

// Extra Pages
import { PersonalizedLandingPage } from '../pages/PersonalizedLandingPage'
import { UnsubscriptionPage } from '../pages/UnsubscribePage'
import { ExploreRedirectPage } from '../pages/ExploreRedirectPage'
import { LeaderboardPage } from '../pages/Leaderboard/LeaderboardPage'
import { WhatIfPage } from '../pages/WhatIfPlanetPage'
import { SnapshotPage } from '../pages/SnapshotPage'
import { SelfServicePage } from '../pages/SelfServicePage'
import { WaterExplorePage } from '../pages/ExplorePages/Climate/Water/WaterExplorePage'

interface Path {
  title: string
  route: Route
  component: React.FC
  children?: Path[]
  useLocationId: boolean
  withAuthentication: boolean
  retailOnly: boolean
}

const routes: Path[] = [
  {
    title: 'Ingka Sustainability - 4Ps',
    route: Route.LandingPage,
    component: PersonalizedLandingPage,
    useLocationId: true,
    withAuthentication: true,
    retailOnly: false
  },
  {
    title: '4P Display Configurator',
    route: Route.InstoreConfiguratorPage,
    component: InStoreConfiguratorPage,
    useLocationId: false,
    withAuthentication: false,
    retailOnly: false
  },
  {
    title: 'Newsletter Unsubscription',
    route: Route.NewsLetterUnsubscribe,
    component: UnsubscriptionPage,
    useLocationId: false,
    withAuthentication: true,
    retailOnly: false
  },
  {
    title: '',
    route: Route.Explore,
    component: ExploreRedirectPage,
    useLocationId: true,
    withAuthentication: true,
    retailOnly: false
  },
  {
    title: 'Snapshot',
    route: Route.SnapshotPage,
    component: SnapshotPage,
    useLocationId: true,
    withAuthentication: true,
    retailOnly: false,
    children: [
      {
        title: 'Zero Waste',
        route: Route.ZeroWasteKPIPage,
        component: ZeroWasteKPIPage,
        useLocationId: true,
        withAuthentication: true,
        retailOnly: false,
        children: [
          {
            title: 'Explore',
            route: Route.ZeroWasteExplorePage,
            component: ZeroWasteExplorePage,
            useLocationId: true,
            withAuthentication: true,
            retailOnly: false
          }
        ]
      },
      {
        title: 'Operational Climate Footprint',
        route: Route.ClimateKPIPage,
        component: ClimateKPIPage,
        useLocationId: true,
        withAuthentication: true,
        retailOnly: false,
        children: [
          {
            title: 'Explore',
            route: Route.ClimateExplorePage,
            component: ClimateExplorePage,
            useLocationId: true,
            withAuthentication: true,
            retailOnly: false
          }
        ]
      },
      {
        title: 'Food Ingredients',
        route: Route.FoodIngredientsKPIPage,
        component: FoodIngredientsKPIPage,
        useLocationId: true,
        withAuthentication: true,
        retailOnly: false
      },
      {
        title: 'Energy Efficiency',
        route: Route.EnergyEfficiencyKPIPage,
        component: EnergyEfficiencyKPIPage,
        useLocationId: true,
        withAuthentication: true,
        retailOnly: false
      },
      {
        title: 'Water Efficiency',
        route: Route.WaterEfficiencyKPIPage,
        component: WaterEfficiencyKPIPage,
        useLocationId: true,
        withAuthentication: true,
        retailOnly: false,
        children: [
          {
            title: 'Explore',
            route: Route.WaterExplorePage,
            component: WaterExplorePage,
            useLocationId: true,
            withAuthentication: true,
            retailOnly: false
          }
        ]
      },
      {
        title: 'Customer Deliveries',
        route: Route.CustomerDeliveriesKPIPage,
        component: CustomerDeliveriesKPIPage,
        useLocationId: true,
        withAuthentication: true,
        retailOnly: false
      },
      {
        title: 'Customer Travel',
        route: Route.CustomerTravelKPIPage,
        component: CustomerTravel,
        useLocationId: true,
        withAuthentication: true,
        retailOnly: false
      },
      {
        title: 'Social Impact',
        route: Route.SocialImpactKPIPage,
        component: SocialImpactKPIPage,
        useLocationId: true,
        withAuthentication: true,
        retailOnly: false
      },
      {
        title: 'Change-makers',
        route: Route.ChangeMakersKPIPage,
        component: ChangeMakersKPIPage,
        useLocationId: true,
        withAuthentication: true,
        retailOnly: false
      },
      {
        title: 'People + Planet Positive Sales',
        route: Route.PppSalesKPIPage,
        component: PppSalesKPIPage,
        useLocationId: true,
        withAuthentication: true,
        retailOnly: true,
        children: [
          {
            title: 'Explore',
            route: Route.PppSalesExplorePage,
            component: PppSalesExplorePage,
            useLocationId: true,
            withAuthentication: true,
            retailOnly: true
          }
        ]
      }
    ]
  },
  {
    title: 'Leaderboard',
    route: Route.LeaderboardPage,
    component: LeaderboardPage,
    useLocationId: true,
    withAuthentication: true,
    retailOnly: false
  },
  {
    title: 'What-If',
    route: Route.WhatIfPlanetPage,
    component: WhatIfPage,
    useLocationId: true,
    withAuthentication: true,
    retailOnly: false
  },
  {
    title: '',
    route: Route.InStorePage,
    component: InStorePage,
    useLocationId: true,
    withAuthentication: false,
    retailOnly: false
  },
  {
    title: '',
    route: Route.SelfServicePage,
    component: SelfServicePage,
    useLocationId: false,
    withAuthentication: true,
    retailOnly: false
  }
]

const redirects: Record<string, string> = {
  [Route.Root]: Route.LandingPage,
  [locationId(Route.WhatIfPage)]: Route.WhatIfPlanetPage,
  [locationId(Route.PerceptionDeprecated)]: Route.SustainabilityMentionsKPIPage,
  [locationId(Route.SocialImpactPageDeprecated)]: Route.SocialImpactKPIPage,
  [locationId(Route.PlanetFootprintPageDeprecated)]: Route.ClimateKPIPage,
  [locationId(Route.ProfitGrowthPageDeprecated)]: Route.PppSalesKPIPage,
  [locationId(Route.ZeroWastePageDeprecated)]: Route.ZeroWasteKPIPage
}

function pathToRoute(path: Path, idx: number): React.ReactElement {
  const authElement = path.withAuthentication ? (
    <WithAuthentication>
      <path.component />
    </WithAuthentication>
  ) : (
    <path.component />
  )
  const pathString = path.useLocationId ? locationId(path.route) : path.route
  const retailElement = path.retailOnly ? <RetailLink>{authElement}</RetailLink> : authElement

  return <ReactRoute key={idx} path={pathString} element={retailElement}></ReactRoute>
}

export function generateRoutes(): React.ReactElement[] {
  const flattenRoutes = (routes: Path[]): Path[] => {
    return routes.flatMap(r => (r.children ? [r, ...flattenRoutes(r.children)] : r))
  }
  return flattenRoutes(routes).map(pathToRoute)
}

function redirectToRoute([from, to]: string[], idx: number): React.ReactNode {
  return <ReactRoute key={idx} path={from} element={<NavigateTo page={to} />} />
}

export function generateRedirects(): React.ReactNode[] {
  return Object.entries(redirects).map(redirectToRoute)
}

export function getBreadCrumbs(route: Route): Path[] {
  let crumbs: Path[] = []

  const findRoute = (r: Path, parents: Path[]) => {
    if (r.route === route) {
      crumbs = [...parents, r]
    }

    if (r.children) {
      r.children.forEach(c => findRoute(c, [...parents, r]))
    }
  }

  routes.forEach(r => findRoute(r, []))

  return crumbs
}
