import { useEffect, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { get, pick, has } from 'lodash'
import qs from 'qs'

import { GraphUnit } from './components/UnitSelector'
import { FunctionArea, SiteFunction } from '../api/src/common-types'
import { useDataAvailabilityContext } from './context'
import { Route } from './routes/Constants'
import { isRoute } from './routes/Utils'
import { getCurrentFunc } from './components/Utils/navigation'

export type AsIsSalesGraph = 'Sales' | 'Index'
export type LeaderboardScope = 'country' | 'site-global' | 'site-country' | 'watchlist'
export type MentionsGraph = 'Sentiments' | 'Topics'
export type WatchlistScope = 'country' | 'site'
export type Scope = 'sbti' | 'oldscope' | 'sales' | 'quantity'

const currentFunctionArea = sessionStorage.getItem('functionArea') as FunctionArea

const currentFunc = currentFunctionArea && (getCurrentFunc(currentFunctionArea) as SiteFunction[])

const defaultFunc: SiteFunction[] = currentFunctionArea ? currentFunc : ['ALL']
const defaultFunctionArea = currentFunctionArea ?? 'ingka'
const scopeValue = 'oldscope'

function getString(queryParams: qs.ParsedQs, path: string, defaultValue: string): string {
  // TODO: Fix typecasting
  return get(queryParams, path, defaultValue) as string
}

function getGraphUnit(queryParams: qs.ParsedQs, path: string, defaultValue: string): GraphUnit {
  const unit = getString(queryParams, path, defaultValue) as GraphUnit
  switch (unit) {
    case GraphUnit.RelativeUnits:
    case GraphUnit.RawUnits:
    case GraphUnit.RelativeRawUnits:
    case GraphUnit.ConvertedUnits:
    case GraphUnit.RawWaste:
    case GraphUnit.RawWasteKg:
    case GraphUnit.RecycledWaste:
    case GraphUnit.Landfill:
      return unit

    default:
      return GraphUnit.ConvertedUnits
  }
}

export interface SharedSelections {
  asisSalesGraph: AsIsSalesGraph
  func: SiteFunction[]
  functionArea: FunctionArea
  leaderboardScope: LeaderboardScope
  mentionsGraph: MentionsGraph
  pathname: string
  rangeFrom: string
  rangeTo: string
  wasteUnit: GraphUnit
  watchlistScope: WatchlistScope
  scope: Scope
}

interface LocationFunctionWithoutChildren {
  type: 'without-children'
  value: SiteFunction
  label: string
  id: FunctionArea
  disabled?: boolean
  children: Omit<LocationFunctionWithoutChildren, 'type' | 'children' | 'id'>[]
}

interface LocationFunctionWithChildren {
  type: 'with-children'
  value: string
  label: string
  id: FunctionArea
  disabled?: boolean
  children: Omit<LocationFunctionWithoutChildren, 'type' | 'children' | 'id'>[]
}

export type LocationFunction = LocationFunctionWithChildren | LocationFunctionWithoutChildren

export type UpdateSharedSelectionsFn = (_: Partial<SharedSelections>) => void

export function createPath(
  selectionsWithPathname: SharedSelections,
  updatesWithPathname: Partial<SharedSelections>
): string {
  const { pathname: selectionsPathname, ...selections } = selectionsWithPathname
  const { pathname: updatesPathname, ...updates } = updatesWithPathname
  const pathname = updatesPathname || selectionsPathname
  const path = pathname

  const queryParametersToShow = (() => {
    if (isRoute(pathname, Route.SnapshotPage)) {
      return pick({ ...selections, ...updates }, ['func', 'functionArea'])
    }

    if (isRoute(pathname, Route.LeaderboardPage)) {
      return pick({ ...selections, ...updates }, ['func', 'functionArea', 'leaderboardScope', 'watchlistScope'])
    }

    if (isRoute(pathname, Route.WhatIfPlanetPage)) {
      return pick({ ...selections, ...updates }, ['func', 'functionArea'])
    }

    if (isRoute(pathname, Route.SustainabilityMentionsKPIPage)) {
      return pick({ ...selections, ...updates }, ['mentionsGraph', 'func', 'functionArea'])
    }

    if (isRoute(pathname, Route.SocialImpactKPIPage)) {
      return pick({ ...selections, ...updates }, ['func', 'functionArea'])
    }

    if (isRoute(pathname, Route.ChangeMakersKPIPage)) {
      return pick({ ...selections, ...updates }, ['func', 'functionArea'])
    }

    if (isRoute(pathname, Route.FoodIngredientsKPIPage)) {
      return pick({ ...selections, ...updates }, ['func', 'functionArea'])
    }

    if (isRoute(pathname, Route.EnergyEfficiencyKPIPage)) {
      return pick({ ...selections, ...updates }, ['func', 'functionArea'])
    }

    if (isRoute(pathname, Route.CustomerTravelKPIPage)) {
      return pick({ ...selections, ...updates }, ['func', 'functionArea'])
    }

    if (isRoute(pathname, Route.ClimateKPIPage)) {
      return pick({ ...selections, ...updates }, ['func', 'functionArea', 'scope'])
    }

    if (isRoute(pathname, Route.ClimateExplorePage)) {
      return pick({ ...selections, ...updates }, ['func', 'functionArea', 'scope'])
    }

    if (isRoute(pathname, Route.PppSalesKPIPage)) {
      return pick({ ...selections, ...updates }, ['func', 'functionArea', 'scope'])
    }

    if (isRoute(pathname, Route.PppSalesExplorePage)) {
      return pick({ ...selections, ...updates }, ['func', 'functionArea'])
    }

    if (isRoute(pathname, Route.CustomerDeliveriesKPIPage)) {
      return pick({ ...selections, ...updates }, ['func', 'functionArea'])
    }

    if (isRoute(pathname, Route.ZeroWasteKPIPage)) {
      return pick({ ...selections, ...updates }, ['func', 'functionArea'])
    }

    if (isRoute(pathname, Route.ZeroWasteExplorePage)) {
      return pick({ ...selections, ...updates }, ['func', 'functionArea', 'wasteUnit'])
    }

    if (isRoute(pathname, Route.LandingPage)) {
      return pick({ ...selections, ...updates }, ['functionArea'])
    }

    if (isRoute(pathname, Route.WaterEfficiencyKPIPage)) {
      return pick({ ...selections, ...updates }, ['func', 'functionArea'])
    }

    if (isRoute(pathname, Route.WaterExplorePage)) {
      return pick({ ...selections, ...updates }, ['func', 'functionArea'])
    }

    return {}
  })()
  const queryParameters = Object.keys(queryParametersToShow).length ? `?${qs.stringify(queryParametersToShow)}` : ''

  return `${path}${queryParameters}`
}

export const functionList: LocationFunction[] = [
  { type: 'without-children', id: 'ingka', value: 'ALL', label: 'Ingka', children: [] },
  {
    type: 'with-children',
    id: 'retail',
    value: 'Retail (All)',
    label: 'Retail',
    children: [
      { value: 'Retail', label: 'Stores' },
      { value: 'Customer Fulfillment', label: 'Customer Fulfilment' },
      { value: 'Support units', label: 'Support Units' }
    ]
  },
  {
    type: 'with-children',
    id: 'centres',
    value: 'Ingka Centres (All)',
    label: 'Ingka Centres incl tenants',
    children: [
      { value: 'Common Areas', label: 'Common Areas' },
      { value: 'Tenants', label: 'Tenants' }
    ]
  }
]

export function useSharedSelections(): [SharedSelections, UpdateSharedSelectionsFn] {
  const navigate = useNavigate()
  const { pathname, search } = useLocation()
  const queryParams = qs.parse(search, { ignoreQueryPrefix: true })
  const { dataAvailability } = useDataAvailabilityContext()

  const selections = {
    asisSalesGraph: getString(queryParams, 'asisSalesGraph', 'Sales') as AsIsSalesGraph,
    func: get(queryParams, 'func', defaultFunc) as SiteFunction[],
    functionArea: getString(queryParams, 'functionArea', defaultFunctionArea) as FunctionArea,
    leaderboardScope: getString(queryParams, 'leaderboardScope', 'country') as LeaderboardScope,
    mentionsGraph: getString(queryParams, 'mentionsGraph', 'Sentiments') as MentionsGraph,
    pathname,
    rangeFrom: getString(queryParams, 'rangeFrom', String((dataAvailability?.profitCurrentFY ?? 1) - 1)),
    rangeTo: getString(queryParams, 'rangeTo', String(dataAvailability?.profitCurrentFY)),
    wasteUnit: getGraphUnit(queryParams, 'wasteUnit', GraphUnit.RawWasteKg),
    watchlistScope: getString(queryParams, 'watchlistScope', 'country') as WatchlistScope,
    scope: get(queryParams, 'scope', scopeValue) as Scope
  }

  const updateSharedSelections = useCallback(
    (updates: Partial<SharedSelections>) => {
      navigate(createPath(selections, updates))
    },
    [history, selections]
  )

  const addDefaults = () => {
    if (pathname.endsWith('/instore')) {
      return
    }
    if (!has(queryParams, 'func') && !has(queryParams, 'functionArea')) {
      updateSharedSelections({ func: defaultFunc, functionArea: defaultFunctionArea })
    } else if (!has(queryParams, 'functionArea')) {
      updateSharedSelections({
        functionArea:
          functionList.find(x => x.value === selections.func[0] || x.children.find(y => y.value === selections.func[0]))
            ?.id ?? 'ingka'
      })
    }
  }

  useEffect(addDefaults, [JSON.stringify(queryParams)])

  return [selections, updateSharedSelections]
}
