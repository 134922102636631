import React from 'react'
import '../../KPIPages/KPIPage.scss'
import { TopBar } from '../../../components/TopBar'
import { PageHeader } from '../../../components/PageHeader'
import { useFilters, fyBetween, TimeRange } from '../ExplorePage'
import { getCluster, getLocationOrDefault, getLocationSelector } from '../../../components/Utils/utils'
import { FootprintType, getTotalFootprint, getPlanetFootprints } from '../../../lib/APIClient'
import { useSharedSelections } from '../../../SharedSelections'
import { ApiTotalFootprint, ApiWasteFootprint } from '../../../../api/src/common-types'
import { NonRecycledWasteGraphs, zeroWasteTypes } from '../../../components/ZeroWasteGraph'
import { ExploreGraphCard } from '../ExploreGraphCard'
import { range, sumBy } from 'lodash'
import { formatAbsoluteNumber } from '../../../components/Utils/format'
import { LoadingSkeleton } from '../../../components/LoadingSkeleton'
import { PlanetWasteByDisposalGraph, PlanetWasteByLocationGraph } from '../../../components/PlanetWasteGraphs'
import { PlanetWasteMapBase, wasteGroupColorMap } from '../../../components/PlanetWasteMap'
import { useDataAvailabilityContext, useLocations } from '../../../context'
import { Route } from '../../../routes'
import { GraphUnit } from '../../../components/UnitSelector'
import { createSeriesWithKey, sumByUnit, WasteByTypeGraph } from '../Climate/ClimateExplorePage'

const kpis = ['zerowaste', 'waste'] as const
const kpiLabels = {
  zerowaste: 'Zero Waste',
  waste: 'Waste'
}

export const ZeroWasteExplorePage = () => {
  const { dataAvailability } = useDataAvailabilityContext()
  const { clusters } = useLocations()
  const [{ func }] = useSharedSelections()
  const [allDates, setAllDates] = React.useState<string[]>()
  const [allFootprint, setAllFootprint] = React.useState<ApiWasteFootprint[]>()
  const [allWaste, setAllWaste] = React.useState<ApiTotalFootprint[]>()
  const [selectedDates, setSelectedDates] = React.useState<Date[]>()
  const [selectedFootprint, setSelectedFootprint] = React.useState<ApiWasteFootprint[]>()
  const [selectedWaste, setSelectedWaste] = React.useState<ApiTotalFootprint[]>()
  const [{ kpi, rangeFrom, rangeTo, timeRange }, filterSelectors] = useFilters(
    kpis,
    kpiLabels,
    dataAvailability?.planetCurrentFY ?? 2000
  )

  const [selectedUnit, setSelectedUnit] = React.useState<string>('tonnes')

  const locationId = getLocationOrDefault()
  const selector = {
    ...getLocationSelector(locationId, getCluster(clusters, locationId)?.countryCodes),
    start_fy: 2016,
    end_fy: dataAvailability?.planetCurrentFY,
    func,
    isOld: true
  }

  const filterData = (
    timeRange: TimeRange,
    rangeFrom: string,
    rangeTo: string,
    footprint?: ApiWasteFootprint[],
    waste?: ApiTotalFootprint[],
    dates?: string[]
  ) => {
    const rangeFromFy = parseInt(rangeFrom) - 2000
    const rangeToFy = parseInt(rangeTo) - 2000

    setSelectedFootprint(timeRange === 'monthly' ? footprint?.filter(fyBetween(rangeFromFy, rangeToFy)) : footprint)
    setSelectedWaste(timeRange === 'monthly' ? waste?.filter(fyBetween(rangeFromFy, rangeToFy)) : waste)
    setSelectedDates(
      timeRange === 'annual'
        ? range(2016 - 2000, rangeToFy + 1).map(fy => new Date(`${fy + 1999}-09-30`))
        : dates
            ?.filter(date => date >= `${parseInt(rangeFrom) - 1}-09-01` && date <= `${parseInt(rangeTo)}-08-31`)
            .map(d => new Date(d))
    )
  }

  React.useEffect(() => {
    const loadData = async () => {
      const [{ dates, data: allWaste }, { data: allFootprint }] = await Promise.all([
        getTotalFootprint(selector, ['waste']),
        getPlanetFootprints(FootprintType.Waste, selector)
      ])

      const allWasteInTonnes = allWaste.map(x => ({ ...x, quantity: x.quantity / 1000 }))

      setAllDates(dates)
      setAllFootprint(allFootprint as ApiWasteFootprint[])
      setAllWaste(allWasteInTonnes)
    }

    setAllDates(undefined)
    setAllFootprint(undefined)
    setAllWaste(undefined)
    setSelectedFootprint(undefined)
    setSelectedWaste(undefined)
    loadData()
  }, [locationId, JSON.stringify(selector)])

  React.useEffect(() => {
    filterData(timeRange, rangeFrom, rangeTo, allFootprint, allWaste, allDates)
  }, [timeRange, rangeFrom, rangeTo, JSON.stringify(allFootprint), JSON.stringify(allWaste), JSON.stringify(allDates)])

  const nonZeroWaste = selectedWaste?.filter(w => !zeroWasteTypes.includes(w.treatmentTypeId))

  const currentUnit = (unit: string) => setSelectedUnit(unit)

  return (
    <div className="KPIPage">
      <TopBar currentPage={Route.ZeroWasteExplorePage} useInFlexLayout />
      <PageHeader className="ZeroWasteHeader" route={Route.ZeroWasteExplorePage}></PageHeader>
      <div className="PageContent">
        {filterSelectors}
        {kpi === 'zerowaste' ? (
          <ExploreGraphCard
            heading="Non-recycled waste"
            amount={
              nonZeroWaste
                ? selectedUnit === 'tonnes'
                  ? formatAbsoluteNumber(Math.round(sumBy(nonZeroWaste, 'quantity')))
                  : formatAbsoluteNumber(Math.round(sumBy(nonZeroWaste, 'footprint')) / 1000)
                : 'N/A'
            }
            unit={selectedUnit}
          >
            <NonRecycledWasteGraphs
              waste={nonZeroWaste}
              dates={selectedDates}
              timeRange={timeRange}
              selectedUnit={currentUnit}
            />
          </ExploreGraphCard>
        ) : (
          <WasteGraphs
            footprint={selectedFootprint}
            waste={selectedWaste}
            dates={selectedDates}
            locationId={locationId}
            timeRange={timeRange}
          />
        )}
      </div>
    </div>
  )
}

interface WasteGraphsProps {
  footprint?: ApiWasteFootprint[]
  waste?: ApiTotalFootprint[]
  dates?: Date[]
  locationId: string
  timeRange: TimeRange
}

const WasteGraphs: React.FC<WasteGraphsProps> = ({ footprint, waste, dates, locationId, timeRange }) => {
  const [byTypeUnit, setByTypeUnit] = React.useState(GraphUnit.RawWaste)
  const [selectedUnit, setSelectedUnit] = React.useState<string>('kg')

  const byTypeSeries = createSeriesWithKey(
    'footprintContributor',
    waste,
    dates,
    timeRange,
    wasteGroupColorMap,
    sumByUnit(byTypeUnit)
  )

  const currentUnitByType = (unit: string) => setSelectedUnit(unit)

  const currentUnit = byTypeUnit === 'rawwaste' ? 'tonnes' : 'tonnes (CO2e)'

  return (
    <>
      <ExploreGraphCard
        heading="Waste Generation by Type"
        amount={
          footprint
            ? currentUnit === 'tonnes'
              ? formatAbsoluteNumber(Math.round(sumBy(waste, 'quantity')))
              : formatAbsoluteNumber(Math.round(sumBy(footprint, 'footprint') / 1000))
            : 'N/A'
        }
        unit={currentUnit === 'tonnes' ? currentUnit : 'tonnes CO2e total'}
      >
        {waste ? (
          <WasteByTypeGraph
            dateFormat={timeRange === 'monthly' ? 'monthWithYear' : 'fy'}
            domain={dates}
            series={byTypeSeries}
            unit={byTypeUnit}
            unitDescription={currentUnit}
            onUnitChange={setByTypeUnit}
          />
        ) : (
          <LoadingSkeleton />
        )}
      </ExploreGraphCard>
      <ExploreGraphCard
        heading="Waste Generation by Location"
        amount={
          footprint && selectedUnit === GraphUnit.RawWasteKg
            ? formatAbsoluteNumber(Math.round(sumBy(footprint, 'footprint')))
            : ''
        }
        unit={selectedUnit === GraphUnit.RawWasteKg ? 'kg CO2e total' : ''}
      >
        {footprint ? (
          <PlanetWasteByLocationGraph
            data={footprint}
            siteOrCountry={locationId}
            dateFormat={timeRange === 'annual' ? 'fy' : 'monthWithYear'}
            selactedUnit={currentUnitByType}
          />
        ) : (
          <LoadingSkeleton />
        )}
      </ExploreGraphCard>
      <ExploreGraphCard
        heading="Waste Treatment by Type"
        amount={footprint ? formatAbsoluteNumber(Math.round(sumBy(footprint, 'raw'))) : 'N/A'}
        unit="kg"
      >
        {footprint ? (
          <PlanetWasteByDisposalGraph
            data={footprint}
            siteOrCountry={locationId}
            dateFormat={timeRange === 'annual' ? 'fy' : 'monthWithYear'}
          />
        ) : (
          <LoadingSkeleton />
        )}
      </ExploreGraphCard>
      <ExploreGraphCard
        heading="Waste Material to Treatment Map"
        amount={footprint ? formatAbsoluteNumber(Math.round(sumBy(footprint, 'raw'))) : 'N/A'}
        unit="kg"
      >
        <PlanetWasteMapBase data={footprint} />
      </ExploreGraphCard>
    </>
  )
}
