import React from 'react'
import { FunctionSelectorTip } from './FunctionSelectorTip'

export const SiteRankModalContent = () => (
  <>
    <div className="IntroParagraph">
      <p>
        Ranking on the Leaderboard shows how sites are doing compared to other sites globally, or compared to sites
        within the selected country. The Leaderboard rank is based on site&apos;s performance across all sustainability
        KPIs. Site ranking is calculating performance across all sites from all functions by default, the final
        composite score only considers relevant KPIs for your site&apos;s function (i.e., only Retail stores include
        P+PP Sales Share, etc.)
      </p>
      <p>
        The Leaderboard is calculated tertial-to-date and the competition is reset with each new tertial. The
        competition ends when all KPI data has been received for the tertial based on standard data reporting lags (e.g.
        2-month lag for climate footprint).
      </p>
    </div>
    <h3>How do we calculate the site rank?</h3>
    <p>Site performance is the standardised, weighted average of the following KPIs:</p>
    <h4>Recycling Rate (% of waste recycled)*</h4>
    <p>(KPI weighting: 25%)</p>
    <h4>Climate Footprint (CO2e/m²)</h4>
    <p>(KPI weighting: 25%)</p>
    <h4>P+PP Sales Share (% of total sales)</h4>
    <p>(KPI weighting: 50%)</p>
    <br />
    <p>
      *The unit of Zero Waste KPI is tonnes of non-recycled waste. However, for a fair comparison in the leaderboard, we
      use a relative measure PI called recycling rate %.
    </p>
    <FunctionSelectorTip text="view rankings within individual functions" />
  </>
)
